import { IconProps } from '@just-ai/just-ui';

export type CategoryValue =
  | 'all'
  | 'beta'
  | 'legal'
  | 'popular'
  | 'favor'
  | 'marketing'
  | 'content'
  | 'texts'
  | 'assistants'
  | 'images'
  | 'data'
  | 'it'
  | 'audio'
  | 'database'
  | 'directAccess';

export type Category = { value: CategoryValue; icon: IconProps['name']; requiredFeature?: string };

export const categories: Category[] = [
  { value: 'popular', icon: 'faThumbsUp' },
  { value: 'marketing', icon: 'faRocketLaunch' },
  { value: 'legal', icon: 'falBalanceScale', requiredFeature: 'copilot_category_legal_access' },
  { value: 'assistants', icon: 'faLightbulb' },
  { value: 'content', icon: 'faShapes' },
  { value: 'data', icon: 'faFileInvoice' },
  { value: 'directAccess', icon: 'faHeadSideBrain' },
  { value: 'all', icon: 'faGlobe' },
  { value: 'favor', icon: 'faStar' },
  { value: 'beta', icon: 'fadStarChristmas', requiredFeature: 'copilot_category_beta_access' },
];

export const categoriesAlpha: Category[] = [
  { value: 'all', icon: 'faGlobe' },
  { value: 'marketing', icon: 'faRocketLaunch' },
  { value: 'it', icon: 'faLaptopCode' },
  { value: 'content', icon: 'faShapes' },
  { value: 'data', icon: 'faFileInvoice' },
  { value: 'favor', icon: 'faStar' },
  { value: 'directAccess', icon: 'faHeadSideBrain' },
  { value: 'database', icon: 'faDatabase' },
];
