var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AnalyticsApi, WebUserApi, APIKeyApi, DataGuardApi, ReportsApi, ReportsApiAxiosParamCreator, } from '../../generated/CopilotGateway';
import { AccessTokenApi } from '../../generated/DataFilter';
export default class GatewayService {
    constructor(axios) {
        this.analyticsApi = new AnalyticsApi({}, GatewayService.BASE_PATH, axios);
        this.webUserApi = new WebUserApi({}, GatewayService.BASE_PATH, axios);
        this.apiKeyApi = new APIKeyApi({}, GatewayService.BASE_PATH, axios);
        this.dataGuardApi = new DataGuardApi({}, GatewayService.BASE_PATH, axios);
        this.accessTokenApi = new AccessTokenApi({}, GatewayService.BASE_PATH, axios);
        this.reportsApi = new ReportsApi({}, GatewayService.BASE_PATH, axios);
    }
    sendEvent(eventData) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.analyticsApi.pushEvent({
                eventName: eventData.eventName,
                version: '1.0',
                origin: eventData.eventValue,
            });
        });
    }
    requestFeature(featureData) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.webUserApi.requestAccess(featureData);
        });
    }
    createApikey(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.apiKeyApi.createApiKey(userId);
        });
    }
    getApiKeys(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.apiKeyApi.getAllApiKeysByUser(userId);
        });
    }
    getCurrentDataGuardKey() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.dataGuardApi.getCurrentApiToken();
        });
    }
    updateApiToken(token, enabled) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.dataGuardApi.updateApiToken({
                apiToken: token,
                enabled,
            });
        });
    }
    getAllDataGuardKeys() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.dataGuardApi.listApiTokens();
        });
    }
    validateJGuardKey() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.dataGuardApi.isApiTokenValid();
        });
    }
    getExpensesHistory({ periodFrom, periodTo, groupBy, userId, conversationId, applicationId, templateId, page, size, sort, userTimeZone, lang, search, }) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.reportsApi.getExpensesHistory(periodFrom, periodTo, userTimeZone, lang, groupBy, userId, search, conversationId, applicationId, templateId, page, size, sort);
        });
    }
    getReplenishmentHistory({ periodFrom, periodTo, page, size, sort, }) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.reportsApi.getReplenishmentHistory(periodFrom, periodTo, page, size, sort);
        });
    }
    createDownloadReportUrl({ periodFrom, periodTo, timezone, lang, userId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const paramCreator = ReportsApiAxiosParamCreator({});
            return paramCreator.getTokenHistoryReport(periodFrom, periodTo, timezone, lang, userId).url;
        });
    }
}
GatewayService.BASE_PATH = '';
