import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classes from './CollapseCustom.module.scss';
import { IconButton, Icon } from '..';
import cn from 'classnames';
export const CustomCollapse = ({ children, title, open = false, onDelete, collapseClassName, headerClassName, headerIconClassName, headerOpenClassName, wrapperClassName, className, chevronPosition = 'left', toggleIcon = 'farChevronUp', customRightElements = null, dataTestId, }) => {
    const [innerOpen, setOpen] = useState(open);
    const chevron = (React.createElement("div", { className: cn(classes['Collapse-custom-heading-icon'], headerIconClassName, {
            [classes['opened-icon']]: innerOpen,
            opened: innerOpen,
        }) },
        React.createElement(Icon, { name: toggleIcon })));
    return (React.createElement("div", { className: cn(classes['Collapse-custom__wrapper'], wrapperClassName, { open: innerOpen }), "data-test-id": dataTestId },
        React.createElement("div", { onClick: () => setOpen(prevOpen => !prevOpen), "data-test-id": 'JGraph.RightSideMenu.Buttons.Callapse', className: cn(classes['Collapse-custom'], className, {
                [classes['opened']]: innerOpen,
                headerOpenClassName: innerOpen,
            }) },
            React.createElement("div", { className: cn(classes['Collapse-custom-heading'], headerClassName) }, chevronPosition === 'left' ? (React.createElement(React.Fragment, null,
                chevron,
                title)) : (React.createElement(React.Fragment, null,
                title,
                chevron))),
            onDelete && React.createElement(IconButton, { "data-test-id": 'Collapse:delete', name: 'farTrashAlt', onClick: onDelete }),
            customRightElements),
        React.createElement(Collapse, { isOpen: innerOpen, className: cn(collapseClassName) }, children)));
};
