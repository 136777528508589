export const isSelected = (selected, option) => {
    return selected.findIndex(selectedItem => selectedItem.value === option.value) > -1;
};
export const makeArrayFromValue = (value) => {
    if (typeof value === 'string' || typeof value === 'number') {
        return [value];
    }
    else if (value instanceof Array) {
        return value;
    }
    return [];
};
export const shouldShowOptionsList = (optionsInView = [], propsOptions = [], selected, multiple, searchValue) => {
    if (multiple) {
        if (searchValue) {
            return optionsInView.length > 0;
        }
        return propsOptions.length - optionsInView.length === selected.length;
    }
    if (searchValue) {
        return optionsInView.length > 0;
    }
    return true;
};
export const getOptionsToView = (submittedSearchOptions, searchValue, propsOptions = [], selectedOptions, multiple) => {
    if (searchValue) {
        return submittedSearchOptions;
    }
    return (submittedSearchOptions.length > 0 ? submittedSearchOptions : propsOptions).filter(option => multiple ? !isSelected(selectedOptions, option) : true);
};
export class Timeout {
    constructor(fn, interval, callback) {
        this.id = null;
        this.cleared = false;
        this.timeoutedId = null;
        this.timeouted = false;
        this.clear = () => {
            this.cleared = true;
            this.id && clearTimeout(this.id);
            this.timeoutedId && clearTimeout(this.timeoutedId);
        };
        this.id = setTimeout(fn, interval);
        this.timeoutedId = setTimeout(() => {
            this.timeouted = true;
            callback();
        }, interval);
    }
}
