export const accountLocalization = {
  'Billing:Modal:title:outOfTokens': {
    eng: 'Not enough tokens',
    ru: 'Не хватает токенов',
  },
  token_one: {
    eng: 'token',
    ru: 'токен',
  },
  token_two: {
    eng: 'tokens',
    ru: 'токена',
  },
  token_few: {
    eng: 'tokens',
    ru: 'токена',
  },
  token_many: {
    eng: 'tokens',
    ru: 'токенов',
  },
  token_other: {
    eng: 'tokens',
    ru: 'токенов',
  },
  jayToken_one: {
    eng: 'J-token',
    ru: 'J-токен',
  },
  jayToken_two: {
    eng: 'J-tokens',
    ru: 'J-токена',
  },
  jayToken_few: {
    eng: 'J-tokens',
    ru: 'J-токена',
  },
  jayToken_many: {
    eng: 'J-tokens',
    ru: 'J-токенов',
  },
  jayToken_other: {
    eng: 'J-tokens',
    ru: 'J-токенов',
  },
  process_one: {
    ru: 'процесс',
    eng: 'process',
  },
  process_two: {
    ru: 'процесса',
    eng: 'processes',
  },
  process_few: {
    ru: 'процесса',
    eng: 'processes',
  },
  process_many: {
    ru: 'процессов',
    eng: 'processes',
  },
  process_other: {
    ru: 'процессов',
    eng: 'processes',
  },
  image_one: {
    eng: 'image',
    ru: 'изображение',
  },
  image_two: {
    eng: 'images',
    ru: 'изображения',
  },
  image_few: {
    eng: 'images',
    ru: 'изображения',
  },
  image_many: {
    eng: 'images',
    ru: 'изображений',
  },
  image_other: {
    eng: 'images',
    ru: 'изображений',
  },
  request_one: {
    eng: 'request',
    ru: 'запрос',
  },
  request_two: {
    eng: 'requests',
    ru: 'запроса',
  },
  request_few: {
    eng: 'requests',
    ru: 'запроса',
  },
  request_many: {
    eng: 'requests',
    ru: 'запросов',
  },
  request_other: {
    eng: 'requests',
    ru: 'запросов',
  },
  character_one: {
    eng: 'character',
    ru: 'символ',
  },
  character_two: {
    eng: 'characters',
    ru: 'символа',
  },
  character_few: {
    eng: 'characters',
    ru: 'символа',
  },
  character_many: {
    eng: 'characters',
    ru: 'символов',
  },
  character_other: {
    eng: 'characters',
    ru: 'символов',
  },
  'Billing:Modal:title': {
    eng: 'Top up balance',
    ru: 'Пополнить баланс',
  },
  'Billing:Modal:title:org': {
    eng: 'Not enough tokens',
    ru: 'Не хватает токенов',
  },
  'Billing:Modal:info:org': {
    eng: 'You have run out of tokens. Please contact our technical support.',
    ru: 'У вас закончились токены. Обратитесь в техническую поддержку.',
  },
  'Billing:Modal:info:admin': {
    eng: 'The token balance is exhausted. Please contact your account administrator.',
    ru: 'Баланс токенов исчерпан. Обратитесь к администратору аккаунта.',
  },
  'Billing:Modal:info': {
    eng: 'You have {balance} {tokenString} left. It is not enough for your request. Buy tokens to continue using #{config.productName}.',
    ru: 'У вас осталось {balance} {tokenString}. Этого не хватит, чтобы выполнить ваш запрос. Купите токены, чтобы продолжить использовать #{config.productName}.',
  },
  'Billing:Card:title': {
    eng: '{amount} {tokenString}',
    ru: '{amount} {tokenString}',
  },
  'Billing:Card:title:corporate': {
    eng: 'Enterprise plan',
    ru: 'Корпоративный тариф',
  },
  'Billing:Card:info:corporate': {
    eng: 'We will provide access to #{config.productName} for you and your colleagues, find an optimal subscription plan and payment method',
    ru: 'Дадим доступ к #{config.productName} для вас и ваших коллег, подберем оптимальный тариф и способ оплаты',
  },
  'Billing:Modal:buy': {
    eng: 'Top up',
    ru: 'Пополнить',
  },
  'Billing:Modal:continue': {
    eng: 'Proceed',
    ru: 'Продолжить',
  },
  'Billing:Modal:sub': {
    eng: 'Pricing details',
    ru: 'Особенности тарификации',
  },
  RequestForFeature: {
    eng: 'You request has been sent',
    ru: 'Заявка отправлена',
  },
  'Modal_RequestFeatureFor_corporate:title': {
    eng: 'Enterprise plan request',
    ru: 'Заявка на корпоративный тариф',
  },
  'Modal_RequestFeatureFor_corporate:info': {
    eng: 'We will install Jay Copilot for your company, discuss advanced usage, and provide flexible pricing.',
    ru: 'Установим Jay Copilot для вашей компании, обсудим расширенное применение, подберем гибкую тарификацию.',
  },
  'Modal_RequestFeatureFor_justGPT:title': {
    eng: 'Be the first to try JustGPT',
    ru: 'Попробуйте JustGPT первыми',
  },
  'Modal_RequestFeatureFor_justGPT:header': {
    eng: 'Use JustGPT — an LLM which is perfect for working with content in Russian',
    ru: 'Используйте JustGPT — LLM, которая отлично подходит для работы с контентом на русском языке',
  },
  'Modal_RequestFeatureFor_justGPT:body': {
    eng: 'This model is trained on more than 70 billion parameters and can handle various business tasks: it generates marketing content, writes code, solves complex problems, and is great for working with legal documents. The application allows you to set up parameters with which you can control the generation of model responses.',
    ru: 'Модель, обученная на более чем 70 миллиардах параметров, прекрасно справляется с различными бизнес-задачами: генерирует маркетинговый контент, пишет код, решает сложные задачи, отлично походит для работы с юридическими документами. В приложении доступна настройка параметров, с помощью которых вы можете управлять генерацией ответов модели.',
  },
  'Modal_RequestFeatureFor:submit': {
    eng: 'Send request',
    ru: 'Оставить заявку',
  },
  'Modal_RequestFeatureFor_mistral:title': {
    eng: 'Try Saiga Mistral first',
    ru: 'Попробуйте приложение Сайга-Мистраль первыми',
  },
  'Modal_RequestFeatureFor_mistral:header': {
    eng: 'Saiga-Mistral is now in #{config.productName}',
    ru: 'Сайга-Мистраль уже в #{config.productName}',
  },
  'Modal_RequestFeatureFor_mistral:body': {
    eng: 'We’ve added the Saiga-Mistral application to #{config.productName} so you can test it out. Be the first to try!',
    ru: 'Мы добавили приложение прямого доступа к модели Сайга-Мистраль, чтобы вы могли потестировать ее. Будьте первыми!',
  },
  'Modal_RequestFeatureFor:subBody': {
    eng: 'Send a request. We will contact you to discuss the project details.',
    ru: 'Оставьте заявку, и мы свяжемся с вами, чтобы обсудить детали проекта.',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:title': {
    eng: 'Try the “Knowledge base Lite” application',
    ru: 'Попробуйте приложение «База знаний Лайт»',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:header': {
    eng: 'Create your own knowledge base!',
    ru: 'Создайте свою базу знаний!',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:body': {
    eng: 'Use your data to create your own knowledge base. The “Knowledge base Lite” application can work with up to 3 documents at once. These can be any materials: regulatory legal acts, user manuals, data tables. The knowledge base provides instant and convenient access to the information you need.',
    ru: 'Используйте ваши данные, чтобы создать свою базу знаний. Приложение «База знаний Лайт» может работать сразу с 3 документами. Это могут быть любые материалы: нормативные правовые акты, руководства пользователя, таблицы с данными. База знаний обеспечит мгновенный и удобный доступ к нужной информации.',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:subBody': {
    eng: 'Leave a request, and we’ll get in touch with you.',
    ru: 'Оставьте заявку, и мы свяжемся с вами.',
  },
  account: {
    eng: 'Account',
    ru: 'Личный кабинет',
  },
  tokenBalance: {
    eng: 'J-token balance',
    ru: 'Баланс J-токенов',
  },
  tokenTooltip: {
    eng: 'J-tokens are the billing units in #{config.productName}. They measure your application’s requests and responses. J-tokens are not the same as the tokens typically used by AI models.',
    ru: 'J-токены — это единицы тарификации в #{config.productName}. В них измеряются ваши запросы и ответы приложений. J-токены не равны токенам, которыми обычно оперируют нейросети.',
  },
  'tokenTooltip:more': {
    eng: 'More info about #{config.productName} billing',
    ru: 'Подробнее о тарификации #{config.productName}',
  },
  payBtnCaption: {
    eng: 'Top up',
    ru: 'Пополнить',
  },
  inviteUsers: {
    eng: 'Invite users',
    ru: 'Пригласить пользователей',
  },
  code: {
    eng: 'Code',
    ru: 'Код',
  },
  activatePromocode: {
    eng: 'Activate promo code',
    ru: 'Активировать промокод',
  },
  'Payment:pending_one': {
    eng: 'Your payment is being processed. We will notify you as soon as it is completed.',
    ru: 'Ваш платеж обрабатывается. Мы сообщим вам, как только он будет завершен.',
  },
  'Payment:pending_many': {
    en: '{amount} {pluralString} of payment have been initiated. We will keep you updated as the payments are processed',
    ru: 'Запущено {amount} {pluralString} оплаты. Мы будем сообщать вам по мере того, как платежи будут обрабатываться',
  },
  'Payment:done': {
    eng: 'Token balance has been topped up. Thank you for using #{config.productName}!',
    ru: 'Баланс токенов пополнен. Спасибо, что пользуетесь #{config.productName}!',
  },
  'Payment:failed': {
    eng: 'Payment failed. Please try again or contact our support team.',
    ru: 'Оплата не прошла. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'Modal_RequestFeature:option:ecom': {
    eng: 'E-com',
    ru: 'E-com',
  },
  'Modal_RequestFeature:option:fintech': {
    eng: 'Fintech',
    ru: 'Fintech',
  },
  'Modal_RequestFeature:option:it': {
    eng: 'IT/Software development',
    ru: 'IT/Разработка ПО',
  },
  'Modal_RequestFeature:option:gov': {
    eng: 'State company',
    ru: 'Гос. компания',
  },
  'Modal_RequestFeature:option:housing': {
    eng: 'Housing and communal services',
    ru: 'ЖКХ',
  },
  'Modal_RequestFeature:option:logistics': {
    eng: 'Logistics/transportation/customs',
    ru: 'Логистика/перевозки/таможня',
  },
  'Modal_RequestFeature:option:marketing': {
    eng: 'Marketing',
    ru: 'Маркетинг',
  },
  'Modal_RequestFeature:option:medicine': {
    eng: 'Medicine/pharmacology/beauty',
    ru: 'Медицина/фармакология/красота',
  },
  'Modal_RequestFeature:option:realEstate': {
    eng: 'Real estate',
    ru: 'Недвижимость',
  },
  'Modal_RequestFeature:option:edu': {
    eng: 'Education/training',
    ru: 'Образование/тренинги',
  },
  'Modal_RequestFeature:option:production': {
    eng: 'Production',
    ru: 'Производство',
  },
  'Modal_RequestFeature:option:industry': {
    eng: 'Industry',
    ru: 'Промышленность',
  },
  'Modal_RequestFeature:option:horeca': {
    eng: 'Restaurants (hospitality industry, delivery)',
    ru: 'Рестораны (HoReCa, доставка)',
  },
  'Modal_RequestFeature:option:retail': {
    eng: 'Retail/FMCG',
    ru: 'Ритейл/FMCG',
  },
  'Modal_RequestFeature:option:trading': {
    eng: 'Retail',
    ru: 'Розничная торговля',
  },
  'Modal_RequestFeature:option:telecom': {
    eng: 'Telecom (contact centers and networks)',
    ru: 'Телеком (КЦ и сети)',
  },
  'Modal_RequestFeature:option:other': {
    eng: 'Other',
    ru: 'Другое',
  },
  'Modal_RequestFeature:label:name': {
    eng: 'Full name',
    ru: 'Имя и фамилия',
  },
  'Modal_RequestFeature:label:companyName': {
    eng: 'Company name',
    ru: 'Название компании',
  },
  'Modal_RequestFeature:label:phone': {
    eng: 'Work phone number',
    ru: 'Рабочий номер телефона',
  },
  'Modal_RequestFeature:label:industry': {
    eng: 'Industry',
    ru: 'Отрасль/индустрия',
  },
  'Modal_RequestFeature:label:email': {
    eng: 'Work email',
    ru: 'Рабочий email',
  },
  'Modal_RequestFeature:label:comment': {
    eng: 'Comment (optional)',
    ru: 'Комментарий (опционально)',
  },
  'Account:Apikey:Modal:title': {
    eng: 'New API key generated',
    ru: 'Новый API-ключ сгенерирован',
  },
  'Account:Apikey:Modal:info': {
    eng: 'The key was copied to the clipboard. Save the key, you cannot get it again. If you have any questions, please contact technical support.',
    ru: 'Ключ скопирован в буфер обмена. Сохраните ключ, получить его повторно невозможно. По всем вопросам обращайтесь в техническую поддержку.',
  },
  'AccountPage:MainTabs:Main': {
    eng: 'General',
    ru: 'Общее',
  },
  'AccountPage:MainTabs:Analytics': {
    eng: 'Analytics',
    ru: 'Аналитика',
  },
  'Analytics:DatePicker:Button': {
    eng: 'Period: ',
    ru: 'Период: ',
  },
  'Analytics:Tab:Expenses': {
    eng: 'Expenses',
    ru: 'Расходы',
  },
  'Analytics:Tab:UserExpenses': {
    eng: 'Users expenses',
    ru: 'Расходы по пользователям',
  },
  'Analytics:Tab:ExpensesByDay': {
    eng: 'Expenses by day',
    ru: 'Расходы по дням',
  },
  'Analytics:Tab:Replenishment': {
    eng: 'Replenishment',
    ru: 'Пополнения',
  },
  'Analytics:Button:Download': {
    eng: 'Download the report in XLSX',
    ru: 'Скачать отчет в XLSX',
  },
  'Analytics:ExpensesByPeriod': {
    eng: 'Consumption for the selected period: ',
    ru: 'Расход за выбранный период: ',
  },
  Tokens: {
    eng: '{tokenAmount} JT',
    ru: '{tokenAmount} JT',
  },
  Expenses: {
    eng: 'Expense',
    ru: 'Расход',
  },
  'Analytics:UserExpenses': {
    eng: "User's expense history",
    ru: 'История расходов пользователя',
  },
  'Analytics:Search:Placeholder': {
    eng: 'Login, email, or username',
    ru: 'Логин, email или имя пользователя',
  },
  'Analytics:Expenses:Empty': {
    eng: 'During this period, Jay Copilot was not used',
    ru: 'В этом периоде Jay Copilot не пользовались',
  },
  'Analytics:Expenses:Empty:Search': {
    eng: 'There are no such users',
    ru: 'Таких пользователей нет',
  },
  'Analytics:Replenish:Empty': {
    eng: 'There are no top-ups in this period',
    ru: 'В этом периоде не было пополнений',
  },
  'Analytics:TokenReplenishType:started': {
    eng: 'Bonus replenishment',
    ru: 'Бонусное пополнение',
  },
  'Analytics:TokenReplenishType:bonus': {
    eng: 'Bonus replenishment',
    ru: 'Бонусное пополнение',
  },
  'Analytics:TokenReplenishType:promo': {
    eng: 'Bonus replenishment',
    ru: 'Бонусное пополнение',
  },
  'Analytics:TokenReplenishType:paid': {
    eng: 'Purchasing a package of tokens',
    ru: 'Покупка пакета токенов',
  },
  'Analytics:TokenReplenishType:tariff': {
    eng: 'Replenishment according to tariff',
    ru: 'Пополнение по тарифу',
  },
};
