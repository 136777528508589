import React from 'react';

import { useTranslation, Checkbox } from '@just-ai/just-ui';
import cn from 'classnames';

import localize from '../../localization';
import JTIcon from './JTIcon';
import styles from './style.module.scss';

type Props = {
  pack: {
    name: string;
    nameNum?: number;
    value: string;
    mj?: string;
    sd?: string;
    gpt?: string;
    gptNum?: number;
    price?: string;
    priceNum?: number;
  };
  setSelectedPackage: (name: string) => void;
  selectedPackage: string;
  dataTestId?: string;
};

export default function PackageBlock(props: Props) {
  const { pack, setSelectedPackage, selectedPackage, dataTestId } = props;

  const { t } = useTranslation();

  return (
    <div
      key={pack.name}
      data-test-id={dataTestId}
      onClick={() => setSelectedPackage(pack.value)}
      className={cn(styles.packages__package, {
        [styles.packages__package__hl]: selectedPackage === pack.value,
      })}
    >
      <Checkbox
        name={`checkbox_${pack.name}`}
        className={styles.packages__checkbox}
        value={selectedPackage === pack.value}
      />
      <h3>
        {pack.name === 'corporate' ? (
          t('Billing:Card:title:corporate')
        ) : (
          <div className='flex'>
            <JTIcon className='mr-3' />
            <p>
              {t('Billing:Card:title', {
                amount: pack.name,
                tokenString: localize.translateWithPlural('jayToken', Number(pack.nameNum)),
              })}
            </p>
          </div>
        )}
      </h3>
      {pack.name === 'corporate' ? (
        <p>
          {t('Billing:Card:info:corporate', {
            productName: t('defaultProductName'),
          })}
        </p>
      ) : (
        <p className='ml-6'>
          <b>{pack.price} ₽</b>
        </p>
      )}
    </div>
  );
}
