import React, { useCallback } from 'react';
import { Button } from "../Button";
import { translate } from "./localization";
import { startOfISOWeek, startOfDay, endOfDay, startOfMonth, endOfMonth, endOfISOWeek, addMonths, startOfWeek, endOfWeek } from "date-fns";
import './FastChoicePeriods.scss';
function FastChoicePeriods({ locale, setTemplateRange, maxDate, baseDate, minDate, singleDateMode }) {
    const date = new Date(baseDate);
    const updateRangeWithMaxCheck = useCallback(({ start, end }) => {
        if (maxDate && end > maxDate)
            end = new Date(maxDate);
        setTemplateRange(start, end);
    }, [setTemplateRange, maxDate]);
    const setThisDay = useCallback(() => {
        const start = startOfDay(date);
        let end = endOfDay(date);
        updateRangeWithMaxCheck({ start, end });
    }, [updateRangeWithMaxCheck, date]);
    const setThisWeek = useCallback(() => {
        const start = startOfDay(locale === 'en' ? startOfWeek(date) : startOfISOWeek(date));
        let end = endOfDay(locale === 'en' ? endOfWeek(date) : endOfISOWeek(date));
        updateRangeWithMaxCheck({ start, end });
    }, [updateRangeWithMaxCheck, date]);
    const setThisMonth = useCallback(() => {
        const start = startOfDay(startOfMonth(date));
        let end = endOfDay(endOfMonth(date));
        updateRangeWithMaxCheck({ start, end });
    }, [updateRangeWithMaxCheck, date]);
    const setPreviousMonth = useCallback(() => {
        const lastMonthDate = addMonths(date, -1);
        const start = startOfDay(startOfMonth(lastMonthDate));
        let end = endOfDay(endOfMonth(lastMonthDate));
        setTemplateRange(start, end);
    }, [setTemplateRange]);
    return React.createElement("div", { className: "rdrFastChoicePeriodsContainer" }, singleDateMode ? React.createElement(Button, { size: "sm", color: "primary", flat: true, onClick: setThisDay, "data-test-id": 'DatePicker.FastChoice.ThisDay' }, translate('This day', locale)) : React.createElement(React.Fragment, null,
        React.createElement(Button, { size: "sm", color: "primary", flat: true, "data-test-id": 'DatePicker.FastChoice.ThisWeek', onClick: setThisWeek }, translate('This week', locale)),
        React.createElement(Button, { size: "sm", color: "primary", flat: true, "data-test-id": 'DatePicker.FastChoice.ThisMonth', onClick: setThisMonth }, translate('This month', locale)),
        React.createElement(Button, { size: "sm", color: "primary", flat: true, "data-test-id": 'DatePicker.FastChoice.LastMonth', onClick: setPreviousMonth }, translate('Last month', locale))));
}
export default React.memo(FastChoicePeriods);
