import React, { useState } from 'react';

import {
  DropdownButton,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  useToggle,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';

import TelegramIcon from '../../../images/TelegramIcon';
import { isMobile } from '../../../utils/app/common';
import { SidebarButton } from '../../Sidebar/SidebarButton';
import styles from './style.module.scss';

export default function CommunityDropdown() {
  const [communityModal, showCommunityModal, hideCommunityModal] = useToggle(false);

  const { t } = useTranslation();

  if (isMobile())
    return (
      <>
        <SidebarButton
          text={t('community')}
          dataTestId='Sidebar:CommunityBtn'
          iconLeft='farComments'
          iconRight='farChevronRight'
          onClick={showCommunityModal}
        />
        <Modal
          isOpen={communityModal}
          className={cn('mobileBottomModal', styles.chatbarSettings__modal)}
          onClickCloseIcon={hideCommunityModal}
          hideFooter
        >
          <a
            href='https://t.me/jay_community'
            rel='noopener noreferrer'
            target='_blank'
            className={cn('d-flex mt-5', styles.chatbarSettings__modalLink)}
          >
            <TelegramIcon />
            <div>
              <p className='text-default'>{t('jayCommunity')}</p>
              <p className='text-sm text-secondary'>{t('jayCommunitySub')}</p>
            </div>
          </a>
          <a
            href='https://t.me/jay_copilot'
            rel='noopener noreferrer'
            target='_blank'
            className={cn('d-flex mb-5', styles.chatbarSettings__modalLink)}
          >
            <TelegramIcon />
            <div>
              <p className='text-default'>{t('jayCopilot')}</p>
              <p className='text-sm text-secondary'>{t('jayCopilotSub')}</p>
            </div>
          </a>
        </Modal>
      </>
    );

  return (
    <DropdownButton data-test-id='dropdown-wrapper' direction='right' className='w-full'>
      <DropdownToggle color='none' className='w-full p-0' data-test-id='dropdown-toggle' size='default'>
        <SidebarButton
          text={t('community')}
          dataTestId='Sidebar:CommunityBtn'
          iconLeft='farComments'
          iconRight='farChevronRight'
        />
      </DropdownToggle>
      <DropdownMenu data-test-id='dropdown-menu'>
        <DropdownItem className='flex'>
          <a href='https://t.me/jay_community' rel='noopener noreferrer' target='_blank' className='d-contents'>
            <TelegramIcon />
            <div>
              <p className='text-default'>{t('jayCommunity')}</p>
              <p className='text-sm text-secondary'>{t('jayCommunitySub')}</p>
            </div>
          </a>
        </DropdownItem>
        <DropdownItem className='flex'>
          <a href='https://t.me/jay_copilot' rel='noopener noreferrer' target='_blank' className='d-contents'>
            <TelegramIcon />
            <div>
              <p className='text-default'>{t('jayCopilot')}</p>
              <p className='text-sm text-secondary'>{t('jayCopilotSub')}</p>
            </div>
          </a>
        </DropdownItem>
      </DropdownMenu>
    </DropdownButton>
  );
}
