import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import { ConversationAndAppCreateRequestApp } from '@just-ai/api/dist/generated/AppsAdapter';
import { Modal, useTranslation } from '@just-ai/just-ui';
import axios, { AxiosError } from 'axios/index';
import { format } from 'date-fns';
import { cloneDeep, isEqual } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppContext } from '../../../contexts/appContext';
import { addAlert } from '../../../models/alerts';
import { createNewDialogFromFavourite, TFavouriteElement } from '../../../models/favourite/favourite.api';
import { goToConversation } from '../../../routes';
import useApiService from '../../../services/useApiService';
import { AgentType } from '../../../types/agents';
import { AgentApiError } from '../../../types/errors';
import { GA } from '../../../utils/app/common';
import FormBuilder from '../components/TemplateForm/FormBuilder';
import { FieldConfig } from '../components/TemplateForm/useFormBuilder';
import jsonData from '../JsonData';

export const CreationFromFavouriteModal: FC<{
  isOpen: boolean;
  template: AgentType;
  templateFields: FieldConfig[];
  favorite: TFavouriteElement;
  closeCreationModal: () => unknown;
  data?: any;
}> = memo(({ isOpen, favorite, template, templateFields, closeCreationModal, data = jsonData }) => {
  const { t } = useTranslation();

  const [inProgress, setInProgress] = useState(false);
  const methods = useForm();
  const { formState, handleSubmit, control, reset, setValue } = methods;

  const cancelRefCreating = useRef<AbortController>();
  const { sendUserActionToAnalytics, createUserChat } = useApiService();
  const { handleNewConversation } = useAppContext();

  useEffect(() => {
    Object.entries(favorite.params).map(([key, val]) => setValue(key, val));
  }, [favorite, setValue]);

  const createNewAgentWithoutConnectionToFavorite = useCallback(
    async (formValues: any) => {
      try {
        cancelRefCreating.current = new AbortController();
        sendUserActionToAnalytics({
          eventName: 'AppSelect',
          eventValue: {
            type: 'app',
            name: favorite.template,
          },
        });
        const { data: res } = await createUserChat(
          {
            app: {
              template: favorite.template,
              params: formValues,
            } as ConversationAndAppCreateRequestApp,
          },
          true,
          cancelRefCreating.current
        );
        const config = { ...cloneDeep(template), params: formValues };
        config.info.title = template.info.title || template.info.title || template.template;
        config.info.title = `${
          favorite?.name || template?.info?.title || template?.info?.title || template?.template || 'Assistant'
        } - ${format(new Date(), 'HH:mm dd.MM')}`;
        const newConversation = await handleNewConversation(res, config);
        sendUserActionToAnalytics({
          eventName: 'AppStart',
          eventValue: {
            type: 'app',
            name: config.template,
          },
        });
        GA('App_launch', config.template);
        goToConversation(newConversation.id);
      } catch (error) {
        const { response } = error as AxiosError<AgentApiError>;
        if (response?.data.error === 'gateway.common.timeout') {
          return addAlert(t('timeoutError'), 'error');
        }
        if (axios.isAxiosError(error) && error.code !== AxiosError.ERR_CANCELED) {
          addAlert(t(response?.data.error ?? 'defaultError', response?.data.args ?? {}), 'error');
        }
      } finally {
        setInProgress(false);
      }
    },
    [createUserChat, favorite?.name, favorite.template, handleNewConversation, sendUserActionToAnalytics, t, template]
  );

  const createNewConversationHandle = useCallback(
    async (formValues?: any) => {
      if (isEqual(formValues, favorite.params)) {
        const conversation = await createNewDialogFromFavourite({
          appId: favorite.id,
          name: favorite.name || '',
        });
        goToConversation(conversation.id);
      } else {
        await createNewAgentWithoutConnectionToFavorite(formValues);
      }
    },
    [createNewAgentWithoutConnectionToFavorite, favorite.id, favorite.name, favorite.params]
  );

  const clearFormValues = useCallback(() => {
    reset({});
  }, [reset]);

  const createNewConversation = useCallback(() => {
    setInProgress(true);
    handleSubmit(createNewConversationHandle)();
  }, [handleSubmit, createNewConversationHandle]);

  return (
    <Modal
      isOpen={isOpen}
      onClosed={clearFormValues}
      className='mobileBottomModal'
      title={favorite.name || template.info.title}
      disableActionButtonAutoFocus
      onCancelClick={closeCreationModal}
      buttonSubmitText={t('Create')}
      buttonCancelText={t('cancel')}
      buttonCancelColor='secondary'
      onActionClick={createNewConversation}
      buttonSubmitDisabled={Object.keys(formState.errors).length > 0 || inProgress}
      inProgress={inProgress}
      noValidate
    >
      <div className='flex flex-column gap-24'>
        <div>
          <b>{template.info.title}</b>
          <p className='mb-0'>{template.info.description}</p>
        </div>
        <FormProvider {...methods}>
          <div className='flex flex-col gap-24'>
            {templateFields.map(field => (
              <FormBuilder
                key={field.name}
                control={control}
                isEdit={false}
                data={data}
                param={field}
                handleFileUpload={() => Promise.resolve(undefined)}
              />
            ))}
          </div>
        </FormProvider>
      </div>
    </Modal>
  );
});

CreationFromFavouriteModal.displayName = 'memo(CreationFromFavouriteModal)';
