var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createRef } from 'react';
import { getCurrentPeriodValueLength, validateAndNormalizeValue, getMaxValueForCurrentMonth, buildCurrentValue, checkIsNeedToMovePosition, } from './InputsHelperFunctions';
import './InputDateAndTime.scss';
import { translate } from './localization';
import classNames from 'classnames';
import { Icon } from '../Icon';
const DATE_ORDER = {
    ru: {
        full: ['day', 'month', 'year', 'hours', 'minutes'],
        date: ['day', 'month', 'year'],
        time: ['hours', 'minutes'],
    },
    en: {
        full: ['month', 'day', 'year', 'hours', 'minutes', 'AM/PM'],
        date: ['month', 'day', 'year'],
        time: ['hours', 'minutes', 'AM/PM'],
    },
};
export class InputDateAndTime extends React.Component {
    constructor() {
        super(...arguments);
        this.ref = createRef();
        this.value = null;
        this.day = null;
        this.year = null;
        this.month = null;
        this.hours = null;
        this.minutes = null;
        this['AM/PM'] = null;
        this.position = this.props.type === 'time' ? 'hours' : 'day';
        this.order = (this.props.locale === 'en' ? DATE_ORDER.en : DATE_ORDER.ru)[this.props.type || 'full'];
        this.clearTrigger = false;
        this.wasOpened = false;
        this.applyValue = (value) => {
            const { type } = this.props;
            if (!type || type === 'date' || type === 'full') {
                this.day = value.getDate();
                this.month = value.getMonth() + 1;
                this.year = value.getFullYear();
            }
            if (!type || type === 'full' || type === 'time') {
                this.minutes = value.getMinutes();
                this.hours = value.getHours();
                this['AM/PM'] = this.hours > 11 ? 1 : null;
            }
            this.wasOpened = true;
            this.updateValueInInput();
        };
        this.onKeyPress = (e) => {
            const key = e.key;
            if (key === 'Tab' && this.order.indexOf(this.position) === this.order.length - 1)
                return;
            e.preventDefault();
            e.stopPropagation();
            if (e.repeat)
                return;
            const value = Number(key);
            if (isNaN(value) || key === ' ') {
                this.clearTrigger = false;
                this.handleKeyCommand(key);
            }
            else {
                this.saveValue(value);
            }
            this.updateValueInInput();
            this.setSelection();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.validateAndCorrect();
                this.props.onChange && this.onChange();
            }, 1000);
        };
        this.handleKeyCommand = (command) => {
            clearTimeout(this.timer);
            switch (command) {
                case 'Enter':
                case 'Escape':
                    this.escape();
                    setTimeout(() => this.props.onEscape && this.props.onEscape(), 0);
                    break;
                case 'Backspace':
                    this.deleteValue();
                    break;
                case 'Delete':
                    this.clearValue();
                    break;
                case 'ArrowLeft':
                    this.setPreviousPosition();
                    break;
                case 'ArrowRight':
                case ' ':
                case 'Tab':
                    this.setNextPositionOrSubmit();
                    break;
                case 'ArrowUp':
                    this.increase();
                    break;
                case 'ArrowDown':
                    this.decrease();
                    break;
                case 'A':
                case 'a':
                    if (this.position !== 'AM/PM')
                        break;
                    this.saveAMPM(null);
                    break;
                case 'p':
                case 'P':
                    if (this.position !== 'AM/PM')
                        break;
                    this.saveAMPM(1);
                    break;
            }
        };
        this.escape = () => {
            var _a;
            clearTimeout(this.timer);
            const { type } = this.props;
            let keysToCheck = [];
            if (this.month === 0)
                this.month = 1;
            if (!type || ['full', 'date'].includes(type)) {
                keysToCheck = ['day', 'month', 'year'];
            }
            else {
                keysToCheck = ['minutes', 'hours'];
            }
            let clearTrigger = false;
            keysToCheck.forEach(key => {
                if (this[key] === null)
                    clearTrigger = true;
            });
            if (clearTrigger) {
                ['day', 'month', 'year', 'minutes', 'hours', 'AM/PM'].forEach((x) => {
                    this[x] = null;
                });
                if (this.ref.current) {
                    this.ref.current.value = '';
                }
            }
            else {
                this.updateValueInInput();
                this.validateAndCorrect(true);
                this.props.onChange && this.onChange();
            }
            (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.blur();
        };
        this.deleteValue = () => {
            const currentValue = this[this.position];
            if (currentValue === null) {
                this.setPreviousPosition();
            }
            else {
                let stringValue = `${currentValue}`;
                stringValue = stringValue.substr(0, stringValue.length - 1);
                this[this.position] = stringValue.length > 0 ? Number(stringValue) : null;
            }
        };
        this.clearValue = () => {
            this[this.position] = null;
            this.updateValueInInput();
        };
        this.clearAllValues = () => {
            var _a;
            this.order.map(x => (this[x] = null));
            if (this.ref.current) {
                this.ref.current.value = '';
            }
            (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.focus();
            this.position = this.order[0];
            this.setSelection();
        };
        this.setPreviousPosition = () => {
            const currentPositionIndex = this.order.indexOf(this.position);
            if (currentPositionIndex === 0)
                return;
            this.position = this.order[currentPositionIndex - 1];
            this.clearTrigger = true;
            this.validateAndUpdateAllValues();
        };
        this.setNextPositionOrSubmit = () => {
            const order = this.order;
            const currentPositionIndex = order.indexOf(this.position);
            if (currentPositionIndex + 1 >= order.length)
                return;
            this.position = order[currentPositionIndex + 1];
            this.clearTrigger = true;
            this.validateAndUpdateAllValues();
        };
        this.increase = () => {
            if (this.position === 'AM/PM')
                return this.saveAMPM(this['AM/PM'] === 1 ? null : 1);
            this[this.position] = this[this.position] === null ? 1 : this[this.position] + 1;
            if (this.position === 'month' && this.month > 12)
                this.month = 1;
            if (this.position === 'day') {
                const maxValue = getMaxValueForCurrentMonth(this.month, this.year);
                if (this.day > maxValue)
                    this.day = 1;
            }
            if (this.position === 'year' && this.year > 9999)
                this.year = 1;
            if (this.position === 'minutes' && this.minutes > 59)
                this.minutes = 0;
            if (this.position === 'hours' && this.hours > 23) {
                this.hours = 0;
                this['AM/PM'] = null;
            }
            if (this.position === 'hours' && this.hours && this.hours > 11) {
                this['AM/PM'] = 1;
            }
            this.clearTrigger = true;
        };
        this.decrease = () => {
            if (this.position === 'AM/PM')
                return this.saveAMPM(this['AM/PM'] === 1 ? null : 1);
            this[this.position] = this[this.position] === null ? 0 : this[this.position] - 1;
            if (['year', 'month', 'day'].includes(this.position) && this[this.position] < 1) {
                if (this.position === 'year')
                    this.year = 9999;
                if (this.position === 'month')
                    this.month = 12;
                if (this.position === 'day')
                    this.day = getMaxValueForCurrentMonth(this.month, this.year);
            }
            if (this.position === 'minutes' && this.minutes < 0)
                this.minutes = 59;
            if (this.position === 'hours' && this.hours < 0) {
                this.hours = 23;
                this['AM/PM'] = 1;
            }
            if (this.position === 'hours' && this.hours && this.hours < 11) {
                this['AM/PM'] = null;
            }
            this.clearTrigger = true;
        };
        this.saveAMPM = (value) => {
            this['AM/PM'] = value;
            if (value === null && this.hours && this.hours > 11)
                this.hours = this.hours - 12;
            if (value === 1 && this.hours !== null && this.hours < 12)
                this.hours = this.hours + 12;
        };
        this.saveValue = (value) => {
            if (this.position === 'AM/PM') {
                this.clearTrigger = false;
                return;
            }
            const currentValue = this[this.position];
            const newValue = `${currentValue === null || this.clearTrigger ? '' : currentValue}${value}`;
            const needToSetNextPosition = checkIsNeedToMovePosition(newValue, this.position, {
                month: this.month,
                year: this.year,
            });
            if (currentValue === null || this.clearTrigger) {
                this[this.position] = value;
                this.clearTrigger = false;
            }
            else {
                this[this.position] = validateAndNormalizeValue(newValue, this.position, [this.day, this.month, this.year], {
                    checkOnZero: needToSetNextPosition,
                    amEnabled: this.order.includes('AM/PM') && this['AM/PM'] === null,
                    minValueSetted: Boolean(this.props.min),
                });
            }
            if (needToSetNextPosition)
                this.setNextPositionOrSubmit();
        };
        this.validateAndUpdateAllValues = () => {
            ['year', 'month', 'day', 'minutes', 'hours'].forEach(period => {
                this[period] = validateAndNormalizeValue(this[period], period, [this.day, this.month, this.year], {
                    checkOnZero: true,
                    amEnabled: this.order.includes('AM/PM') && this['AM/PM'] === null,
                    minValueSetted: this.props.min !== undefined,
                });
            });
        };
        this.updateValueInInput = () => __awaiter(this, void 0, void 0, function* () {
            const { day, month, year, minutes, hours } = this;
            const value = buildCurrentValue(this.order, { day, month, year, minutes, hours, ampm: this['AM/PM'] }, this.props.locale);
            if (this.ref.current) {
                this.ref.current.value = value;
                this.ref.current.dataset.clean =
                    this.order.filter(position => {
                        return this[position] !== null;
                    }).length > 0
                        ? 'false'
                        : 'true';
                this.forceUpdate();
            }
            if (Boolean(this.value) !== Boolean(value)) {
                this.value = value;
                this.forceUpdate();
            }
        });
        this.getValueAsDate = () => {
            const { day, month, year, minutes, hours } = this;
            const result = new Date();
            year && result.setFullYear(year);
            month && result.setMonth(month - 1);
            day && result.setDate(day);
            result.setSeconds(0);
            hours !== null && result.setHours(hours);
            minutes !== null && result.setMinutes(minutes);
            return result;
        };
        this.validateAndCorrect = (needForceCorrect = false) => {
            const { min, max, type } = this.props;
            let { year, month, day } = this;
            if (type === 'time')
                return;
            if (min === undefined && max === undefined)
                return;
            if (year === null || month === null || day === null)
                return;
            if (min !== undefined && year && year < (needForceCorrect ? 10 : 1000))
                return;
            let result = this.getValueAsDate();
            if (min !== undefined && new Date(min) > result) {
                this.applyValue(new Date(min));
            }
            if (max !== undefined && new Date(max) < result) {
                this.applyValue(new Date(max));
            }
        };
        this.onChange = () => {
            const { type, onChange, min } = this.props;
            const { day, month, year, minutes, hours } = this;
            if (type === 'time' && minutes !== null && hours !== null) {
                const value = `${this.hours}:${this.minutes}`;
                onChange && onChange(value);
            }
            else if ((!type || type === 'full' || type === 'date') && day && month && year) {
                if (min !== undefined && year < 1000)
                    return;
                let result = this.getValueAsDate();
                onChange && onChange(+result, result);
            }
        };
        this.onFirstClick = () => {
            this.wasOpened = true;
            this.position = this.order[0];
            this.updateValueInInput();
            setTimeout(this.setSelection, 0);
            return;
        };
        this.findWhatPeriodClickedAndSetSelection = (e) => {
            //without timeout browser for first click always gives selectionStart as 0
            setTimeout(() => {
                var _a;
                const currentFocusPosition = (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.selectionStart;
                if (typeof currentFocusPosition !== 'number')
                    return;
                let offset = 0;
                this.order.forEach(period => {
                    const value = this[period];
                    const nextOffset = offset + getCurrentPeriodValueLength(value, period) + 1;
                    if (currentFocusPosition >= offset && currentFocusPosition <= nextOffset) {
                        this.position = period;
                    }
                    offset = nextOffset;
                });
                this.clearTrigger = true;
                this.updateValueInInput();
                setTimeout(this.setSelection, 0);
            }, 0);
        };
        this.setSelection = () => __awaiter(this, void 0, void 0, function* () {
            let offsetPeriods = this.order.slice(0, this.order.indexOf(this.position));
            const offsetNumber = offsetPeriods.reduce((result, period) => {
                const value = this[period];
                result += getCurrentPeriodValueLength(value, period) + 1;
                return result;
            }, 0);
            if (this.ref.current && this.ref.current === document.activeElement) {
                const currentPeriodValue = this[this.position];
                this.ref.current.selectionStart = offsetNumber;
                const lengthOfValue = getCurrentPeriodValueLength(currentPeriodValue, this.position);
                this.ref.current.selectionEnd = offsetNumber + lengthOfValue;
            }
        });
    }
    componentDidMount() {
        const { defaultValue, value } = this.props;
        if (defaultValue || value) {
            this.applyValue(defaultValue || value);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    componentDidUpdate(prevProps, prev, snapshot) {
        const currentValue = ['number', 'object'].includes(typeof prevProps.value) ? prevProps.value : false;
        // @ts-ignore
        const nextValue = ['number', 'object'].includes(typeof this.props.value) ? this.props.value : false;
        // @ts-ignore
        if (prevProps.locale !== this.props.locale || prevProps.type !== this.props.type) {
            this.order = (this.props.locale === 'en' ? DATE_ORDER.en : DATE_ORDER.ru)[this.props.type || 'full'];
            this.updateValueInInput();
        }
        if (currentValue !== nextValue && nextValue) {
            this.applyValue(nextValue);
            this.setSelection();
        }
        else if (currentValue !== nextValue && !nextValue) {
            this.clearAllValues();
            this.setSelection();
        }
    }
    render() {
        const { type, locale, clearable, name, id, placeholder, dark } = this.props;
        const focusHandler = this.wasOpened === true || this.value ? this.findWhatPeriodClickedAndSetSelection : this.onFirstClick;
        return (React.createElement("div", { className: classNames('rdrInputDateContainer', `rdrInputDateContainer${type}`, {
                rdrDark: dark,
            }) },
            React.createElement("input", { ref: this.ref, name: name, id: id, type: 'text', onBlur: this.escape, onClick: focusHandler, onFocus: focusHandler, onKeyDown: this.onKeyPress, spellCheck: false, placeholder: placeholder || translate(`${type || 'full'} placeholder`, locale), "data-test-id": 'JustUI.DatePicker.DateTimeInput', className: 'rdrInputDate' }),
            clearable && (React.createElement("button", { tabIndex: -1, onClick: this.clearAllValues },
                React.createElement(Icon, { name: 'faTimesCircle', size: 'md', color: 'secondary' })))));
    }
}
