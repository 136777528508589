import { effect, signal } from '@preact/signals-react';
import { Draft } from 'immer';

import { appOptions } from '../../api/cc.api';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { appsAdapterService } from '../../services/service';
import { Conversation } from '../../types/chat';
import { templates, templatesLoaded } from '../templates';
import { getUserChatHistory } from './apiHooks';
import {
  conversations,
  conversationsLoaded,
  rawConversations,
  rawConversationsLoaded,
  setConversationsValue,
} from './signals';
import { rawConversationToConversation } from './utils';

effect(() => {
  if (!conversationsLoaded.value && rawConversationsLoaded.value && templatesLoaded.value) {
    conversationsLoaded.value = true;

    const conversations = rawConversations.value.flatMap(rawChat => {
      const chatTemplate = templates.value.templatesMap[rawChat.app?.template] || rawChat.app;

      if (!chatTemplate) return [];

      return rawConversationToConversation(rawChat, chatTemplate);
    });
    setConversationsValue(conversations);
  }
});

const isRequestProcessing = signal(false);
let lastScroll: string | undefined = undefined;
effect(async () => {
  if (conversations.value.some(conversation => conversation.status === 'BUILDING') && !isRequestProcessing.value) {
    isRequestProcessing.value = true;
    const {
      data: { conversations: conversationsFromApi },
    } = await appsAdapterService.getUserChats(true);

    const currentPageUpdate = conversationsFromApi?.find(
      conversation => conversation.status === 'READY' && window.location.pathname.includes(conversation.id)
    );
    setConversationsValue(prevConversations => {
      const reversedConversationsFromApi = [...(conversationsFromApi ?? [])].reverse();
      reversedConversationsFromApi.forEach(conversationFromApi => {
        const updatedConversationIndex = prevConversations.findIndex(({ id }) => id === conversationFromApi.id);
        const updatedConversation = prevConversations.splice(updatedConversationIndex, 1)[0];
        prevConversations.unshift({
          ...(conversationFromApi as Draft<Conversation>),
          config: updatedConversation.config,
          contextValue: updatedConversation.contextValue,
          history: updatedConversation.history,
          messageIsStreaming: conversationFromApi.status !== 'READY',
          streamSubscription: updatedConversation.streamSubscription,
        });
      });
    });
    if (conversationsFromApi?.length && conversationsFromApi.length > 0) {
      const firstDialog = document.querySelector('[data-test-id="Conversation.0"]');
      const firstDialogId = firstDialog?.getAttribute('href') ?? undefined;
      if (firstDialog && firstDialogId !== lastScroll) {
        firstDialog.scrollIntoView({ behavior: 'smooth', block: 'end' });
        lastScroll = firstDialogId;
      }
    }
    if (!!currentPageUpdate) {
      await getUserChatHistory(currentPageUpdate.id, currentPageUpdate.app.template);
      guideTourEvent$.next(`GetResponse:${currentPageUpdate.app.template}`);
    }
    setTimeout(() => {
      isRequestProcessing.value = false;
    }, 2000);
  }
});

const defaultIconElement = document.head.querySelector('#default_icon') as HTMLLinkElement;
const hasNewMessagesIconElement = document.head.querySelector('#has_new_messages_icon') as HTMLLinkElement;

const defaultIconHref = defaultIconElement?.href;
const hasNewMessagesIconHref = hasNewMessagesIconElement?.href;
effect(() => {
  if (!hasNewMessagesIconHref || !defaultIconHref || appOptions.value?.product?.favicon) return;
  if (conversations.value.some(conversation => conversation.hasUnreadChanges)) {
    defaultIconElement.href = hasNewMessagesIconHref;
  } else {
    defaultIconElement.href = defaultIconHref;
  }
});
