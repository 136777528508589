import React, { useCallback, useEffect, useState } from 'react';

import { ExpenseField, ExpensesHistoryResponse, ReportUser } from '@just-ai/api/dist/generated/CopilotGateway';
import { Button, Icon, Spinner, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import useApiService from 'services/useApiService';

import { useAppContext } from '../../../../contexts/appContext';
import { Drawer } from '../../../Drawer';
import { AnalyticsDatePicker } from '../AnalyticsDatePicker';
import { DonutChart } from '../DonutChart';
import { AnalyticsTable } from '../Tables';
import { downloadReportAsFile } from '../utils';
import styles from './styles.module.scss';

export const UserExpenses = ({
  drawerData,
  close,
  initialDates,
}: {
  drawerData: ReportUser;
  close: () => void;
  initialDates: Range;
}) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState(initialDates);

  const [userExpenses, setUserExpenses] = useState<ExpensesHistoryResponse>();
  const { getExpensesHistory, getExpensesReportUrl } = useApiService();
  const { state: appState } = useAppContext();
  const { paginationInfo, changePage } = usePagination({
    pageNum: userExpenses?.page.number ?? 0,
    pageSize: userExpenses?.page.size ?? 10,
    totalCount: userExpenses?.page.totalElements ?? 0,
    totalPages: userExpenses?.page.totalPages ?? 0,
  });

  const [{ loading }, getDefaultExpensesData] = usePromiseProcessing(
    async () => {
      if (!drawerData) return;
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.Date, ExpenseField.Conversation],
        userId: drawerData.id,
        locale: appState.locale,
        page: paginationInfo.pageNum,
        size: paginationInfo.pageSize,
      });
      setUserExpenses(data);
    },
    {
      deps: [dates, getExpensesHistory, drawerData.id, paginationInfo.pageNum, paginationInfo.pageSize],
    }
  );

  useEffect(() => {
    getDefaultExpensesData();
  }, [getDefaultExpensesData]);

  const handleCloseDrawer = useCallback(() => {
    close();
  }, [close]);

  const [{ loading: reportLoading }, getExpensesReportLocal] = usePromiseProcessing(
    async () => {
      if (!drawerData) return;
      const url = await getExpensesReportUrl({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        userId: drawerData.id,
        locale: appState.locale,
      });
      downloadReportAsFile(
        url,
        `Report: ${dates.startDate.toLocaleDateString(appState.locale)} - ${dates.endDate.toLocaleDateString(
          appState.locale
        )}.xlsx`
      );
    },
    {
      deps: [dates, getExpensesReportUrl, drawerData.id],
    }
  );

  return (
    <Drawer isOpen={Boolean(drawerData)} onClose={handleCloseDrawer}>
      {(loading || reportLoading) && <Spinner />}
      <div className={styles.UserExpenses__header}>
        <h1>{t('Analytics:UserExpenses')}</h1>
        <Icon name='falTimes' size='lg' onClick={handleCloseDrawer} />
      </div>
      <div className={styles.UserExpenses__content}>
        <div className={styles.UserExpenses__userdata}>
          <h3>{drawerData.email}</h3>
          <h3>{drawerData.name}</h3>
        </div>
        <div className='flex w-full justify-between items-center'>
          <AnalyticsDatePicker dates={dates} setDates={setDates} />
          {/*
          TODO return after backend fixes
          <Button
            color='secondary'
            outline
            size='sm'
            className='d-none d-sm-flex'
            iconRight='farArrowToBottom'
            onClick={getExpensesReportLocal}
          >
            {t('Analytics:Button:Download')}
          </Button>
           */}
        </div>
        <hr />
        {userExpenses && userExpenses.page.totalElements > 0 && <DonutChart dates={dates} userId={drawerData.id} />}
        <AnalyticsTable data={userExpenses} hasPermission={false} tab='expenses' />
        {paginationInfo.totalPages > 1 && (
          <Pagination
            page={paginationInfo.pageNum}
            size={paginationInfo.pageSize}
            changePage={changePage}
            totalCount={paginationInfo.totalCount}
          />
        )}
      </div>
    </Drawer>
  );
};
