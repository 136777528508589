var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component, createRef } from 'react';
import { addMonths, differenceInCalendarMonths } from 'date-fns';
import { DateRange } from 'react-date-range';
// @ts-ignore
import { en, ru, cn, pt } from 'react-date-range/dist/locale';
import './DatePicker.scss';
import classNames from 'classnames';
import { Months } from './Months';
import FastChoicePeriods from './FastChoicePeriods';
import { Inputs } from './Inputs';
import { clampMinDate, getEndOfDay, getEndOfMinute } from '../utils';
const locales = { en, ru, cn, pt };
export class DatePickerState {
    constructor() {
        this.range = null;
        this.currentDate = new Date();
        this.showCalendar = false;
    }
}
const maxPossibleDate = new Date('January 1, 2026 00:00:00');
export class DatePicker extends Component {
    constructor() {
        super(...arguments);
        this.state = new DatePickerState();
        this.initialRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        };
        this.maxDate = new Date('January 1, 2026 00:00:00');
        this.datePicker = null;
        this.rangeContainer = createRef();
        this.calendarContainerRef = createRef();
        this.baseDate = new Date();
        this.updateLocalMaxDate = (maxDate) => {
            if (!maxDate) {
                this.maxDate = maxPossibleDate;
            }
            else {
                this.maxDate = maxDate > maxPossibleDate ? maxPossibleDate : new Date(maxDate);
            }
            this.forceUpdate();
        };
        this.changeDisplayedMonths = (value, mode = 'monthOffset') => {
            const { maxDate } = this.props;
            let maxDateWithoutTime = new Date(maxDate !== null && maxDate !== void 0 ? maxDate : maxPossibleDate);
            let currentDateWithoutTime = new Date(this.state.currentDate);
            maxDateWithoutTime === null || maxDateWithoutTime === void 0 ? void 0 : maxDateWithoutTime.setHours(0, 0, 0, 0);
            currentDateWithoutTime === null || currentDateWithoutTime === void 0 ? void 0 : currentDateWithoutTime.setHours(0, 0, 0, 0);
            if (this.datePicker !== null &&
                ((maxDate && value > 0 && currentDateWithoutTime < maxDateWithoutTime) || !maxDate || value < 0)) {
                //without monkey patching calendar would be move back to current month after changing year or month
                const { calendar } = this.datePicker;
                if (calendar.updateShownDate) {
                    calendar.updateShownDate = () => { };
                }
                const currentDate = new Date(this.state.currentDate);
                this.datePicker.calendar.changeShownDate(value, mode);
                this.setState({ currentDate: addMonths(currentDate, value) });
            }
        };
        this.changeDisplayedMonthsWithYearAndMonth = (month, year, forceApply) => {
            const { currentDate } = this.state;
            const { maxDate } = this.props;
            let choosenPeriod = new Date(0);
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            choosenPeriod.setMonth(month);
            choosenPeriod.setFullYear(year);
            let diffInMonth = 0;
            if (choosenPeriod > currentDate) {
                const diffInYear = year - currentYear;
                diffInMonth = month + diffInYear * 12 - currentMonth;
            }
            else {
                const diffInYear = currentYear - year;
                diffInMonth = month - (currentMonth + diffInYear * 12);
            }
            if (maxDate && maxDate < choosenPeriod)
                return;
            this.changeDisplayedMonths(diffInMonth);
            if (forceApply)
                this.apply();
        };
        this.update = (key, value) => {
            let { range, currentDate } = this.state;
            if (range && !this.props.singleDateMode) {
                range[key] = value;
            }
            else {
                range = { startDate: value, endDate: value, key: 'selection' };
            }
            const diffInMonths = (value.getFullYear() - currentDate.getFullYear()) * 12 + value.getMonth() - currentDate.getMonth();
            this.changeDisplayedMonths(diffInMonths);
            this.setState({ range }, () => {
                if (this.props.integratedPicker)
                    this.apply();
            });
        };
        this.setTime = (key, value) => {
            var _a, _b;
            const { range } = this.state;
            const hours = Number(value.split(':')[0]);
            const minutes = Number(value.split(':')[1]);
            if (range && range[key]) {
                range[key] = new Date(range[key]);
                (_a = range[key]) === null || _a === void 0 ? void 0 : _a.setHours(hours);
                (_b = range[key]) === null || _b === void 0 ? void 0 : _b.setMinutes(minutes);
            }
            this.setState({ range });
        };
        this.clampDate = (range) => {
            const { singleDateMode = false, fixedPeriodInMonths = null, fixedPeriodInDays = null, minDate } = this.props;
            if (!this.props.withTime)
                range.endDate = getEndOfDay(range.endDate);
            else
                range.endDate = getEndOfMinute(range.endDate);
            if (singleDateMode && (range === null || range === void 0 ? void 0 : range.endDate) && !fixedPeriodInMonths && !fixedPeriodInDays) {
                range = Object.assign(Object.assign({}, range), { startDate: range.endDate });
                range.startDate = clampMinDate(range.startDate, minDate);
            }
            return Object.assign(Object.assign({}, range), { startDate: new Date(range.startDate), endDate: new Date(range.endDate) });
        };
        this.setRange = (item) => __awaiter(this, void 0, void 0, function* () {
            const { fixedPeriodInMonths = null, fixedPeriodInDays = null } = this.props;
            let range = item.selection;
            if (fixedPeriodInMonths || fixedPeriodInDays) {
                if (item.selection.startDate.getTime() !== item.selection.endDate.getTime()) {
                    range.startDate = new Date(range.endDate);
                }
                let newEndDate = new Date(range.endDate);
                let endDateWithFixedPeriod = fixedPeriodInMonths
                    ? new Date(newEndDate.setMonth(newEndDate.getMonth() + fixedPeriodInMonths))
                    : new Date(newEndDate.setDate(newEndDate.getDate() + (fixedPeriodInDays !== null && fixedPeriodInDays !== void 0 ? fixedPeriodInDays : 0)));
                range = Object.assign(Object.assign({}, range), { startDate: new Date(range.startDate), endDate: new Date(endDateWithFixedPeriod) });
            }
            range = this.clampDate(range);
            this.setState({ range }, () => {
                if (this.props.integratedPicker)
                    this.apply();
            });
        });
        this.setTemplateRange = (startDate, endDate) => {
            const { calendar } = this.datePicker;
            if (calendar.updateShownDate) {
                calendar.updateShownDate = () => { };
            }
            this.setState({
                range: {
                    startDate,
                    endDate,
                    key: 'selection',
                },
            }, () => {
                this.changeDisplayedMonthsWithYearAndMonth(startDate.getMonth(), startDate.getFullYear(), true);
            });
        };
        this.showCalendar = () => {
            var _a, _b;
            this.setState({ showCalendar: true });
            if (!this.props.integratedPicker) {
                document.addEventListener('mousedown', this.handleClickToClose);
                (_b = (_a = this.calendarContainerRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', this.handleKeyDownOnCalendar, { capture: true });
            }
        };
        this.handleKeyDownOnCalendar = (e) => {
            var _a, _b;
            switch (e.key) {
                case 'Enter':
                case 'Escape':
                    e.preventDefault();
                    e.stopPropagation();
                    (_b = (_a = this.calendarContainerRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('keydown', this.handleKeyDownOnCalendar);
                    this.apply();
                    break;
            }
        };
        this.handleClickToClose = (e) => {
            var _a, _b;
            if (!e && this.props.integratedPicker) {
                return;
            }
            if ((!this.props.integratedPicker && !e) || !((_b = (_a = this.rangeContainer) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.contains(e === null || e === void 0 ? void 0 : e.target))) {
                document.removeEventListener('mousedown', this.handleClickToClose);
                setTimeout(this.apply, 0);
            }
        };
        this.apply = () => {
            const { onChange, singleDateMode = false } = this.props;
            const { range: rawRange } = this.state;
            let range = rawRange;
            if ((range === null || range === void 0 ? void 0 : range.startDate) && (range === null || range === void 0 ? void 0 : range.startDate) > (range === null || range === void 0 ? void 0 : range.endDate)) {
                range = { startDate: range.endDate, endDate: range.startDate, key: range.key };
            }
            if (onChange)
                onChange(singleDateMode ? (range === null || range === void 0 ? void 0 : range.startDate) || null : range);
            this.setState({ showCalendar: false, range });
        };
        this.onKeyDown = (e) => {
            switch (e.key) {
                case 'Enter':
                case 'Escape':
                    this.handleClickToClose();
                    break;
            }
        };
    }
    componentDidMount() {
        const { defaultValue, maxDate, baseDate } = this.props;
        const { currentDate } = this.state;
        if (baseDate) {
            this.baseDate = new Date(baseDate);
        }
        this.updateLocalMaxDate(maxDate);
        if (!defaultValue)
            return;
        if (defaultValue instanceof Date) {
            this.changeDisplayedMonths(differenceInCalendarMonths(defaultValue, currentDate));
            this.setState({
                range: {
                    startDate: new Date(defaultValue),
                    endDate: new Date(defaultValue),
                    key: 'selection',
                },
            });
        }
        else if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.endDate) {
            this.changeDisplayedMonths(differenceInCalendarMonths(defaultValue.startDate, currentDate));
            this.setState({ range: Object.assign(Object.assign({}, defaultValue), { key: 'selection' }) });
        }
        if (this.datePicker) {
            const { calendar } = this.datePicker;
            if (calendar.updateShownDate) {
                calendar.updateShownDate = () => { };
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.maxDate !== this.props.maxDate) {
            this.updateLocalMaxDate(this.props.maxDate);
        }
        if (this.props.openCalendar && this.props.openCalendar !== prevProps.openCalendar && this.props.openCalendar) {
            this.showCalendar();
        }
        if (!this.props.openCalendar && this.props.openCalendar !== prevProps.openCalendar && !this.props.defaultValue) {
            this.handleClickToClose();
        }
    }
    render() {
        const _a = this.props, { locale = 'en', dark = false, disabledDates, singleDateMode = false, withTime = true, onChange, placeholderStart, placeholderEnd, minDate = 0, yearsRange, rootClassName, calendarClassName, inputsRootClassName, positionFixed = false, integratedPicker = false, hideInputs = false, months = 2 } = _a, rest = __rest(_a, ["locale", "dark", "disabledDates", "singleDateMode", "withTime", "onChange", "placeholderStart", "placeholderEnd", "minDate", "yearsRange", "rootClassName", "calendarClassName", "inputsRootClassName", "positionFixed", "integratedPicker", "hideInputs", "months"]);
        const { range, currentDate, showCalendar } = this.state;
        return (React.createElement("div", { onKeyDown: this.onKeyDown, className: classNames('rdrPositionContainer', 'rdrCustomContainerForDatePicker', rootClassName, {
                rdrShowPositionContainer: showCalendar,
                rdrDark: dark,
                rdrIntegratedCalendar: integratedPicker,
            }) },
            React.createElement("div", { className: 'rdrContainer', onFocus: !showCalendar ? this.showCalendar : undefined, ref: this.rangeContainer },
                !hideInputs && (React.createElement(Inputs, { type: withTime ? 'full' : 'date', locale: locale, range: range, minDate: minDate, maxDate: this.maxDate, setTime: this.update, onEscape: () => this.handleClickToClose(), rootClassName: inputsRootClassName, dark: dark, opened: showCalendar, singleDateMode: singleDateMode, placeholderStart: placeholderStart, placeholderEnd: placeholderEnd, onReset: () => this.setState({ range: null }), columns: singleDateMode ? 1 : months })),
                React.createElement("div", { ref: this.calendarContainerRef, className: classNames(classNames('rdrCalendarContainer', 'rdrCustomContainerForDatePicker', calendarClassName, {
                        rdrShowCalendar: showCalendar || integratedPicker,
                        rdrDatePickerSingleMode: singleDateMode || months === 1,
                        rdrCalendarFixed: positionFixed,
                        rdrDark: dark,
                    })) },
                    React.createElement(Months, { setMonth: this.changeDisplayedMonths, locale: locales[locale], yearsRange: yearsRange, singleDateMode: singleDateMode || months === 1, maxDate: this.maxDate, minDate: minDate ? new Date(minDate) : undefined, setYearAndMonth: this.changeDisplayedMonthsWithYearAndMonth, showCalendar: showCalendar || integratedPicker, currentDate: currentDate }),
                    React.createElement(DateRange, Object.assign({ locale: locales[locale], ref: (ref) => (this.datePicker = ref), dragSelectionEnabled: !singleDateMode, onChange: this.setRange, showSelectionPreview: true, showMonthAndYearPickers: true, showPreview: !singleDateMode, moveRangeOnFirstSelection: false, disabledDates: disabledDates, onShownDateChange: (currentDate) => this.setState({ currentDate }), weekDaysFormat: 'dd', ranges: [range || this.initialRange], months: singleDateMode ? 1 : months, fixedHeight: true, monthDisplayFormat: 'MMMM YYYY', direction: 'horizontal', showDateDisplay: false, 
                        //@ts-ignore
                        minDate: minDate ? new Date(minDate) : undefined }, rest)),
                    React.createElement(FastChoicePeriods, { singleDateMode: singleDateMode, minDate: minDate ? new Date(minDate) : undefined, maxDate: this.maxDate, setTemplateRange: this.setTemplateRange, baseDate: this.baseDate, locale: locale })))));
    }
}
