import React, { useEffect, useMemo, useState } from 'react';

import { ExpenseField } from '@just-ai/api/dist/generated/CopilotGateway';
import { Spinner, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { Range } from '@just-ai/just-ui/dist/DatePicker';
import cn from 'classnames';
import { Doughnut as Donut } from 'react-chartjs-2';
import useApiService from 'services/useApiService';

import { useAppContext } from '../../../../contexts/appContext';
import { createChartLabel } from '../utils';
import styles from './styles.module.scss';

export const DonutChart = ({ dates, userId }: { dates: Range; userId?: number }) => {
  const { t } = useTranslation();
  const { getExpensesHistory } = useApiService();
  const { state: appState } = useAppContext();
  const [data, setData] = useState<{ value: number; title: string }[]>([]);

  const [{ loading: dataLoading }, getDonutData] = usePromiseProcessing(
    async () => {
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy: [ExpenseField.Template],
        locale: appState.locale,
        size: 100,
        userId,
      });
      const processedData = data.expenses.map(expense => ({
        title: expense.template?.name || '',
        value: expense.tokenAmount,
      }));
      processedData.sort((a, b) => b.value - a.value);
      setData(processedData);
    },
    {
      deps: [dates, getExpensesHistory],
    }
  );

  useEffect(() => {
    getDonutData();
  }, [getDonutData]);

  const donutData = useMemo(() => {
    const labelsArr = data.map(data => data.title);
    const values = data.map(data => data.value);
    const colorsMap = ['#9D0D6C', '#D6A7F0', '#553A76', '#7FDB83'];
    const colors = new Array(values.length)
      .fill('')
      .map((item, index) => colorsMap[index] || colorsMap[colorsMap.length - 1]);
    return {
      donutData: {
        labels: labelsArr,
        datasets: [
          {
            data: values,
            backgroundColor: colors,
            borderWidth: values.map(value => 0),
          },
        ],
      },
      chipsData: data.map((data, index) => ({
        ...data,
        color: colors[index] || colors[colors.length - 1],
        textColor: '#fff',
      })),
    };
  }, [data]);

  const totalSpend = data.reduce((total, data) => {
    return total + data.value;
  }, 0);

  if (dataLoading) {
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.DoughnutChart}>
      <p className='margin-bottom-8'>
        {t('Analytics:ExpensesByPeriod')}
        <strong>{t('Tokens', { tokenAmount: totalSpend.toFixed(3) })}</strong>
      </p>
      <div className='flex gap-8 justify-between'>
        <div>
          <div className={styles.DoughnutChart__chips}>
            {donutData.chipsData.map(data => (
              <div
                className={cn(styles.DoughnutChart__chip, 'font-size-12')}
                key={data.title}
                style={{ backgroundColor: data.color, color: data.textColor }}
              >{`${data.title}: ${data.value.toFixed(3)} JT`}</div>
            ))}
          </div>
        </div>
        <div style={{ width: 240, height: 120 }} className='d-none d-sm-block'>
          <Donut
            data={donutData.donutData}
            options={{
              rotation: 0,
              cutoutPercentage: 70,
              tooltipCaretSize: 0,
              width: 120,
              height: 120,
              borderWidth: [0],
              tooltips: {
                displayColors: false,
                backgroundColor: '#6A7178',
                callbacks: {
                  label: createChartLabel,
                },
              },
            }}
            legend={{
              display: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};
