import React, { memo, useState } from 'react';

import { Icon } from '@just-ai/just-ui';

import { LinkMessage } from '../../types/chat';
import ImageGallery from './ImageGallery';
import styles from './style.module.scss';

type Props = {
  messageType: string;
  messageTimestamp?: string;
  file?: string | File;
  link?: LinkMessage;
  name?: string;
};

const PRIVATE_LINK_REGEXP = new RegExp('^/api/appsAdapter/files/[0-9a-fA-F-]+/download$');

export const FileMessage = memo(({ file, link, messageTimestamp, name }: Props) => {
  const [error, setError] = useState(false);
  const imagesTypes = ['jpeg', 'jpg', 'png', 'svg', 'bmp'];
  let partsUrl = typeof file === 'string' ? file.split('.') : [];
  // private link doesn't contain extension. So try to extract it from file name
  if (link?.url && PRIVATE_LINK_REGEXP.test(new URL(link.url).pathname)) {
    partsUrl = name?.split('.') ?? [];
  }
  const extension = partsUrl[partsUrl.length - 1];

  if (error && typeof file === 'string') {
    if (!extension && link) {
      return <a href={link.url}>{link.text}</a>;
    }

    return (
      <a className={styles.chat__fileMessage} href={file} download>
        <p>{name || file}</p>
        <Icon name='farArrowToBottom' color='none' size='sm' />
      </a>
    );
  }

  if (link && name && !imagesTypes.includes(extension)) {
    return (
      <a className={styles.chat__fileMessage} href={link.url} download>
        <p>{name}</p>
        <Icon name='farArrowToBottom' color='none' size='sm' />
      </a>
    );
  }

  if (link && !name)
    return (
      <a href={link.url} download>
        {link.text}
      </a>
    );
  if (!file) return null;
  if (typeof file === 'string')
    return (
      <ImageGallery
        errorHandler={() => setError(true)}
        galleryID={`gallery-${messageTimestamp}`}
        images={[{ largeURL: file, thumbnailURL: file }]}
      />
    );
  if (file.type.includes('image'))
    return (
      <ImageGallery
        galleryID={`gallery-${messageTimestamp}`}
        images={[{ largeURL: URL.createObjectURL(file), thumbnailURL: URL.createObjectURL(file) }]}
      />
    );
  return (
    <a className={styles.chat__fileMessage} href={URL.createObjectURL(file)} download>
      <p>{name || file.name}</p>
      <Icon name='farArrowToBottom' color='none' size='sm' />
    </a>
  );
});

export default FileMessage;
