import { OptionsResponseDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { AppLogger } from '@just-ai/logger';
import { signal } from '@preact/signals-react';
import { AxiosError } from 'axios';
import Keycloak, { KeycloakInitOptions, KeycloakOnLoad, KeycloakPkceMethod } from 'keycloak-js';

import { isDev } from '../utils/app/common';
import apiClient, { goToKeycloakLogin } from './client';

type CC_response = {
  authentication: {
    authenticationType: 'CC' | 'KEYCLOAK' | 'NONE';
    keycloak?: {
      url: string;
      realm: string;
      clientId: string;
      onLoad: KeycloakOnLoad;
      pkceMethod?: KeycloakPkceMethod;
      checkLoginIframe?: boolean;
    };
  };
  billingEnabled?: boolean;
  language: 'EN' | 'RU';
  docsUrl: string;
  demoHosts?: string[];
  appUrl?: string;
  systemFeatures?: string[];
  product?: {
    logo?: string;
    favicon?: string;
    name?: string;
    tos?: string;
  };
};

export const useCloudLogin = signal(false);

export const useKeycloakLogin = signal(false);
export const keycloakLogin = signal<CC_response['authentication']['keycloak']>(undefined);
export const userKeycloakAuthHeaderSet = signal(false);

export const appOptions = signal<CC_response | undefined>(undefined);
export const ccOptions = signal<OptionsResponseDto | undefined>(undefined);

export let keycloakInstance: Keycloak | null = null;
export let keycloakInitOptions: KeycloakInitOptions | null = null;

export const getCCOptions = async () => {
  try {
    const { data } = await apiClient.get<OptionsResponseDto>('/api/accountsadmin/public/options');
    ccOptions.value = data;
  } catch (e) {
    if ((e as AxiosError).code) {
      AppLogger.error({
        message: `Error getting app options`,
        exception: e as AxiosError,
      });
    }
  }
};

export const getOptions = async (callbacks?: { callbackPositive?: () => void; callbackNegative?: () => void }) => {
  try {
    const { data } = await apiClient.get<CC_response>('/api/gateway/public/options');
    useCloudLogin.value = data.authentication.authenticationType === 'CC';
    if (data.authentication.authenticationType === 'KEYCLOAK') {
      useKeycloakLogin.value = true;
      keycloakLogin.value = data.authentication.keycloak;
      keycloakInstance = new Keycloak({
        url: data.authentication.keycloak?.url,
        realm: data.authentication.keycloak?.realm || '',
        clientId: data.authentication.keycloak?.clientId || '',
      });

      keycloakInitOptions = {
        onLoad: data.authentication.keycloak?.onLoad,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        pkceMethod: data.authentication.keycloak?.pkceMethod,
        checkLoginIframe: data.authentication.keycloak?.checkLoginIframe,
        redirectUri: window.location.origin,
      };

      await goToKeycloakLogin(apiClient, keycloakInstance, keycloakInitOptions, callbacks);
    }
    if (useCloudLogin.value) {
      await getCCOptions();
    }
    appOptions.value = { ...data };
    return data;
  } catch (e) {
    useCloudLogin.value = false;
  }
};

export const ccLogout = () => {
  window.location.href = isDev()
    ? `${window.location.protocol}//localhost:3001/c/logout`
    : `${window.location.origin}/c/logout?redirectUrl=${window.location.origin}`;
};

export const goToSelectAccount = () => {
  window.location.href = isDev()
    ? `${window.location.protocol}//localhost:3001/c/select-account`
    : `${window.location.origin}/c/select-account`;
};

export const goToUnsupportedAccount = () => {
  window.location.href = isDev()
    ? `${window.location.protocol}//localhost:3001/c/account-or-user-unsupported`
    : `${window.location.origin}/c/account-or-user-unsupported`;
};

export const appHasSystemFeature = (feature: string) => appOptions.value?.systemFeatures?.includes(feature);
