var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Input } from 'reactstrap';
import { IconButton } from '../Button';
import { Icon } from '../Icon';
import ErrorText from '../ErrorText';
import clamp from '../utils/clamp';
const inputSizeToIcon = {
    lg: 'lg',
    sm: 'sm',
    md: 'md',
    undefined: 'md',
    default: 'md',
};
const voidFn = () => { };
export function InputTextBase(_a) {
    var { type = 'text', name, value, warning, validPositionLeft, clearable, clearButtonProps, loadable, className, hint, compact, bsSize, onChange = voidFn, onClear = voidFn, blurInactive, withEvent, errorText, min = -Infinity, max = Infinity } = _a, restProps = __rest(_a, ["type", "name", "value", "warning", "validPositionLeft", "clearable", "clearButtonProps", "loadable", "className", "hint", "compact", "bsSize", "onChange", "onClear", "blurInactive", "withEvent", "errorText", "min", "max"]);
    const inputRef = React.useRef(null);
    //prevent value change on scroll
    const handleWheel = React.useCallback(event => {
        if (type === 'number') {
            event.preventDefault();
        }
        return;
    }, [type]);
    React.useEffect(() => {
        var _a;
        const currentRef = inputRef.current;
        if (restProps.innerRef) {
            (_a = restProps.innerRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('wheel', handleWheel, { passive: false });
        }
        else if (inputRef && inputRef.current) {
            inputRef.current.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            var _a;
            if (restProps.innerRef) {
                (_a = restProps.innerRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('wheel', handleWheel, { passive: false });
            }
            else if (currentRef) {
                currentRef.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleWheel, restProps.innerRef]);
    const onChangeInner = useCallback((event) => {
        let val = event.target.value;
        if (type === 'number') {
            if (val !== '') {
                val = clamp(min, max)(parseInt(val.toString()));
            }
        }
        return withEvent ? onChange(val, name, event) : onChange(val, name);
    }, [type, withEvent, onChange, name, min, max]);
    if (blurInactive) {
        restProps.onBlur = (e) => e.target.classList.add('blur_inactive');
        restProps.onFocus = (e) => e.target.classList.add('blur_inactive');
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, Object.assign({ type: type, name: name, value: value, bsSize: compact ? 'sm' : bsSize, onChange: onChangeInner, className: classNames(className, 'justui_input-text', {
                'is-warning': warning,
                positionLeft: validPositionLeft,
                clearable: clearable && !restProps.disabled && !restProps.readOnly,
                loadable: loadable && !restProps.disabled && !restProps.readOnly,
                blur_inactive: blurInactive,
            }), innerRef: restProps.innerRef || inputRef, min: min, max: max }, restProps)),
        clearable && !restProps.disabled && !restProps.readOnly ? (React.createElement(IconButton, Object.assign({ onClick: () => {
                onClear();
                onChange('', name);
            }, flat: true, className: 'clear', size: bsSize, withoutPadding: true, color: 'secondary', name: 'faTimesCircle' }, clearButtonProps))) : null,
        loadable && !restProps.disabled && !restProps.readOnly ? (React.createElement(Icon, { className: 'loadable fa-spin', size: inputSizeToIcon[bsSize], color: 'secondary', name: 'faSpinnerThird' })) : null,
        errorText && React.createElement(ErrorText, { text: errorText, testId: restProps['data-test-id'] }),
        hint && !errorText && React.createElement("span", { className: 'hint', dangerouslySetInnerHTML: { __html: hint } })));
}
