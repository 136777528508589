import React, { useRef, useMemo, useCallback, Ref } from 'react';

import { Placement } from '@floating-ui/core/src/types';
import { ComputePositionConfig } from '@floating-ui/dom/src/types';
import { shift, offset, hide, flip } from '@floating-ui/react-dom';
import useFloaterPosition from 'modules/GuideTour/hooks';

import { Step } from '../../types';
import Tooltip from '../Tooltip';

interface FloaterInterface {
  continuous: boolean;
  helpers: any;
  index: number;
  size: number;
  step: Step;
  getPopper: (tooltip: any, type?: string) => void;
  setTooltipRef: (tooltip: any) => void;
  isOpen: boolean;
  arrowRef?: Ref<HTMLElement>;
  placement: Placement;
  target: HTMLElement;
}
const Floater = function ({
  continuous,
  getPopper,
  setTooltipRef,
  placement,
  helpers,
  step,
  isOpen,
  size,
  index,
}: FloaterInterface) {
  const tooltipRef = useRef<HTMLElement | null>(null);
  const arrowRef = useRef<HTMLElement | null>(null);

  const floaterOptions = useMemo<Partial<ComputePositionConfig>>(
    () => ({
      strategy: 'absolute',
      placement,
      middleware: [
        offset({
          mainAxis: 20,
        }),
        shift(),
        flip(),
        hide({
          padding: 20,
        }),
      ],
    }),
    [placement]
  );

  const targetNode = useMemo(() => {
    if (typeof step.target === 'string') {
      return document.querySelector(step.target) as HTMLElement;
    }
    return step.target;
  }, [step.target]);

  useFloaterPosition({
    enable: isOpen,
    floaterElement: tooltipRef,
    target: targetNode,
    arrowElement: arrowRef,
    options: floaterOptions,
  });

  const setTooltipRefInner = useCallback(
    (el: HTMLDivElement) => {
      getPopper(el, 'wrapper');
      getPopper(el);
      tooltipRef.current = el;
    },
    [getPopper]
  );

  return (
    <div
      style={{
        display: isOpen ? 'block' : 'none',
        position: 'absolute',
        zIndex: 9999,
      }}
      ref={setTooltipRefInner}
    >
      <Tooltip
        continuous={continuous}
        helpers={helpers}
        index={index}
        isLastStep={index + 1 === size}
        setTooltipRef={setTooltipRef}
        size={size}
        step={step}
        arrowRef={arrowRef}
      />
    </div>
  );
};

export default React.memo(Floater);
