import { ExpenseField, Expenses, Replenishment } from '@just-ai/api/dist/generated/CopilotGateway';
import { DateTime } from 'luxon';

export const GroupExpensesByDay = (data: Array<Expenses>) => {
  return data.reduce((prevValue, currentValue, currentIndex, originalArr) => {
    if (currentIndex === 0 || !currentValue.date) return [...prevValue, currentValue];

    const formattedDate = DateTime.fromJSDate(new Date(currentValue.date));
    const prevDate = originalArr[currentIndex - 1].date;
    const prevFormattedDate = prevDate && DateTime.fromJSDate(new Date(prevDate));
    if (prevFormattedDate && formattedDate.toISODate() === prevFormattedDate.toISODate()) {
      return [...prevValue, { ...currentValue, date: undefined }];
    }
    return [...prevValue, currentValue];
  }, [] as Array<Expenses>);
};

export const GroupReplenishesByDay = (data: Array<Omit<Replenishment, 'timestamp'> & { timestamp?: Date }>) => {
  return data.reduce((prevValue, currentValue, currentIndex) => {
    if (currentIndex === 0 || !currentValue.timestamp) return [...prevValue, currentValue];
    const formattedDate = DateTime.fromJSDate(new Date(currentValue.timestamp));
    const prevDate = prevValue[prevValue.length - 1].timestamp;
    const prevFormattedDate = prevDate && DateTime.fromJSDate(new Date(prevDate));
    if (prevFormattedDate && formattedDate.toISODate() === prevFormattedDate.toISODate()) {
      return [...prevValue, { ...currentValue, timestamp: undefined }];
    }
    return [...prevValue, currentValue];
  }, [] as Array<Omit<Replenishment, 'timestamp'> & { timestamp?: Date }>);
};

export type ChartTooltip = {
  index: number;
};

export type ChartData = {
  labels: string[];
  datasets: {
    data: any[];
  }[];
};

export function createChartLabel(tooltipItem: ChartTooltip, data: ChartData) {
  return `${data['labels'][tooltipItem['index']]} ${data['datasets'][0]['data'][tooltipItem['index']]} JT`;
}

export function getExpenseRequestParams(hasInvitedUsers: boolean, isAdmin: boolean) {
  const sortByDate = ['date', 'desc'];
  const sortByExpenses = ['tokenAmount', 'desc'];
  if (hasInvitedUsers && isAdmin) return { groupBy: [ExpenseField.User], sort: sortByExpenses };
  if (!hasInvitedUsers && isAdmin) return { groupBy: [ExpenseField.Date, ExpenseField.Conversation], sort: sortByDate };
  return { groupBy: [ExpenseField.Date, ExpenseField.Conversation], sort: sortByDate };
}

export function downloadReportAsFile(url: string, name?: string) {
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name || `report - ${Date.now().toLocaleString()}.xlsx`); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
