// @ts-nocheck
// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 * @export
 * @enum {string}
 */
export var ApiKeyCreateRequestTypeEnum;
(function (ApiKeyCreateRequestTypeEnum) {
    ApiKeyCreateRequestTypeEnum["FILTRATION"] = "FILTRATION";
    ApiKeyCreateRequestTypeEnum["APIACCESS"] = "API_ACCESS";
})(ApiKeyCreateRequestTypeEnum || (ApiKeyCreateRequestTypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var ApiKeyResponseTypeEnum;
(function (ApiKeyResponseTypeEnum) {
    ApiKeyResponseTypeEnum["FILTRATION"] = "FILTRATION";
    ApiKeyResponseTypeEnum["APIACCESS"] = "API_ACCESS";
})(ApiKeyResponseTypeEnum || (ApiKeyResponseTypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var ApiKeyUpdateRequestTypeEnum;
(function (ApiKeyUpdateRequestTypeEnum) {
    ApiKeyUpdateRequestTypeEnum["FILTRATION"] = "FILTRATION";
    ApiKeyUpdateRequestTypeEnum["APIACCESS"] = "API_ACCESS";
})(ApiKeyUpdateRequestTypeEnum || (ApiKeyUpdateRequestTypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var AuditEventActionEnum;
(function (AuditEventActionEnum) {
    AuditEventActionEnum["Allow"] = "allow";
    AuditEventActionEnum["Redact"] = "redact";
    AuditEventActionEnum["Deny"] = "deny";
})(AuditEventActionEnum || (AuditEventActionEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var AuditEventDestinationResponseStatusEnum;
(function (AuditEventDestinationResponseStatusEnum) {
    AuditEventDestinationResponseStatusEnum["_100CONTINUE"] = "100 CONTINUE";
    AuditEventDestinationResponseStatusEnum["_101SWITCHINGPROTOCOLS"] = "101 SWITCHING_PROTOCOLS";
    AuditEventDestinationResponseStatusEnum["_102PROCESSING"] = "102 PROCESSING";
    AuditEventDestinationResponseStatusEnum["_103CHECKPOINT"] = "103 CHECKPOINT";
    AuditEventDestinationResponseStatusEnum["_200OK"] = "200 OK";
    AuditEventDestinationResponseStatusEnum["_201CREATED"] = "201 CREATED";
    AuditEventDestinationResponseStatusEnum["_202ACCEPTED"] = "202 ACCEPTED";
    AuditEventDestinationResponseStatusEnum["_203NONAUTHORITATIVEINFORMATION"] = "203 NON_AUTHORITATIVE_INFORMATION";
    AuditEventDestinationResponseStatusEnum["_204NOCONTENT"] = "204 NO_CONTENT";
    AuditEventDestinationResponseStatusEnum["_205RESETCONTENT"] = "205 RESET_CONTENT";
    AuditEventDestinationResponseStatusEnum["_206PARTIALCONTENT"] = "206 PARTIAL_CONTENT";
    AuditEventDestinationResponseStatusEnum["_207MULTISTATUS"] = "207 MULTI_STATUS";
    AuditEventDestinationResponseStatusEnum["_208ALREADYREPORTED"] = "208 ALREADY_REPORTED";
    AuditEventDestinationResponseStatusEnum["_226IMUSED"] = "226 IM_USED";
    AuditEventDestinationResponseStatusEnum["_300MULTIPLECHOICES"] = "300 MULTIPLE_CHOICES";
    AuditEventDestinationResponseStatusEnum["_301MOVEDPERMANENTLY"] = "301 MOVED_PERMANENTLY";
    AuditEventDestinationResponseStatusEnum["_302FOUND"] = "302 FOUND";
    AuditEventDestinationResponseStatusEnum["_302MOVEDTEMPORARILY"] = "302 MOVED_TEMPORARILY";
    AuditEventDestinationResponseStatusEnum["_303SEEOTHER"] = "303 SEE_OTHER";
    AuditEventDestinationResponseStatusEnum["_304NOTMODIFIED"] = "304 NOT_MODIFIED";
    AuditEventDestinationResponseStatusEnum["_305USEPROXY"] = "305 USE_PROXY";
    AuditEventDestinationResponseStatusEnum["_307TEMPORARYREDIRECT"] = "307 TEMPORARY_REDIRECT";
    AuditEventDestinationResponseStatusEnum["_308PERMANENTREDIRECT"] = "308 PERMANENT_REDIRECT";
    AuditEventDestinationResponseStatusEnum["_400BADREQUEST"] = "400 BAD_REQUEST";
    AuditEventDestinationResponseStatusEnum["_401UNAUTHORIZED"] = "401 UNAUTHORIZED";
    AuditEventDestinationResponseStatusEnum["_402PAYMENTREQUIRED"] = "402 PAYMENT_REQUIRED";
    AuditEventDestinationResponseStatusEnum["_403FORBIDDEN"] = "403 FORBIDDEN";
    AuditEventDestinationResponseStatusEnum["_404NOTFOUND"] = "404 NOT_FOUND";
    AuditEventDestinationResponseStatusEnum["_405METHODNOTALLOWED"] = "405 METHOD_NOT_ALLOWED";
    AuditEventDestinationResponseStatusEnum["_406NOTACCEPTABLE"] = "406 NOT_ACCEPTABLE";
    AuditEventDestinationResponseStatusEnum["_407PROXYAUTHENTICATIONREQUIRED"] = "407 PROXY_AUTHENTICATION_REQUIRED";
    AuditEventDestinationResponseStatusEnum["_408REQUESTTIMEOUT"] = "408 REQUEST_TIMEOUT";
    AuditEventDestinationResponseStatusEnum["_409CONFLICT"] = "409 CONFLICT";
    AuditEventDestinationResponseStatusEnum["_410GONE"] = "410 GONE";
    AuditEventDestinationResponseStatusEnum["_411LENGTHREQUIRED"] = "411 LENGTH_REQUIRED";
    AuditEventDestinationResponseStatusEnum["_412PRECONDITIONFAILED"] = "412 PRECONDITION_FAILED";
    AuditEventDestinationResponseStatusEnum["_413PAYLOADTOOLARGE"] = "413 PAYLOAD_TOO_LARGE";
    AuditEventDestinationResponseStatusEnum["_413REQUESTENTITYTOOLARGE"] = "413 REQUEST_ENTITY_TOO_LARGE";
    AuditEventDestinationResponseStatusEnum["_414URITOOLONG"] = "414 URI_TOO_LONG";
    AuditEventDestinationResponseStatusEnum["_414REQUESTURITOOLONG"] = "414 REQUEST_URI_TOO_LONG";
    AuditEventDestinationResponseStatusEnum["_415UNSUPPORTEDMEDIATYPE"] = "415 UNSUPPORTED_MEDIA_TYPE";
    AuditEventDestinationResponseStatusEnum["_416REQUESTEDRANGENOTSATISFIABLE"] = "416 REQUESTED_RANGE_NOT_SATISFIABLE";
    AuditEventDestinationResponseStatusEnum["_417EXPECTATIONFAILED"] = "417 EXPECTATION_FAILED";
    AuditEventDestinationResponseStatusEnum["_418IAMATEAPOT"] = "418 I_AM_A_TEAPOT";
    AuditEventDestinationResponseStatusEnum["_419INSUFFICIENTSPACEONRESOURCE"] = "419 INSUFFICIENT_SPACE_ON_RESOURCE";
    AuditEventDestinationResponseStatusEnum["_420METHODFAILURE"] = "420 METHOD_FAILURE";
    AuditEventDestinationResponseStatusEnum["_421DESTINATIONLOCKED"] = "421 DESTINATION_LOCKED";
    AuditEventDestinationResponseStatusEnum["_422UNPROCESSABLEENTITY"] = "422 UNPROCESSABLE_ENTITY";
    AuditEventDestinationResponseStatusEnum["_423LOCKED"] = "423 LOCKED";
    AuditEventDestinationResponseStatusEnum["_424FAILEDDEPENDENCY"] = "424 FAILED_DEPENDENCY";
    AuditEventDestinationResponseStatusEnum["_425TOOEARLY"] = "425 TOO_EARLY";
    AuditEventDestinationResponseStatusEnum["_426UPGRADEREQUIRED"] = "426 UPGRADE_REQUIRED";
    AuditEventDestinationResponseStatusEnum["_428PRECONDITIONREQUIRED"] = "428 PRECONDITION_REQUIRED";
    AuditEventDestinationResponseStatusEnum["_429TOOMANYREQUESTS"] = "429 TOO_MANY_REQUESTS";
    AuditEventDestinationResponseStatusEnum["_431REQUESTHEADERFIELDSTOOLARGE"] = "431 REQUEST_HEADER_FIELDS_TOO_LARGE";
    AuditEventDestinationResponseStatusEnum["_451UNAVAILABLEFORLEGALREASONS"] = "451 UNAVAILABLE_FOR_LEGAL_REASONS";
    AuditEventDestinationResponseStatusEnum["_500INTERNALSERVERERROR"] = "500 INTERNAL_SERVER_ERROR";
    AuditEventDestinationResponseStatusEnum["_501NOTIMPLEMENTED"] = "501 NOT_IMPLEMENTED";
    AuditEventDestinationResponseStatusEnum["_502BADGATEWAY"] = "502 BAD_GATEWAY";
    AuditEventDestinationResponseStatusEnum["_503SERVICEUNAVAILABLE"] = "503 SERVICE_UNAVAILABLE";
    AuditEventDestinationResponseStatusEnum["_504GATEWAYTIMEOUT"] = "504 GATEWAY_TIMEOUT";
    AuditEventDestinationResponseStatusEnum["_505HTTPVERSIONNOTSUPPORTED"] = "505 HTTP_VERSION_NOT_SUPPORTED";
    AuditEventDestinationResponseStatusEnum["_506VARIANTALSONEGOTIATES"] = "506 VARIANT_ALSO_NEGOTIATES";
    AuditEventDestinationResponseStatusEnum["_507INSUFFICIENTSTORAGE"] = "507 INSUFFICIENT_STORAGE";
    AuditEventDestinationResponseStatusEnum["_508LOOPDETECTED"] = "508 LOOP_DETECTED";
    AuditEventDestinationResponseStatusEnum["_509BANDWIDTHLIMITEXCEEDED"] = "509 BANDWIDTH_LIMIT_EXCEEDED";
    AuditEventDestinationResponseStatusEnum["_510NOTEXTENDED"] = "510 NOT_EXTENDED";
    AuditEventDestinationResponseStatusEnum["_511NETWORKAUTHENTICATIONREQUIRED"] = "511 NETWORK_AUTHENTICATION_REQUIRED";
})(AuditEventDestinationResponseStatusEnum || (AuditEventDestinationResponseStatusEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var AuditEventStatusEnum;
(function (AuditEventStatusEnum) {
    AuditEventStatusEnum["MASKED"] = "MASKED";
    AuditEventStatusEnum["UNMASKED"] = "UNMASKED";
})(AuditEventStatusEnum || (AuditEventStatusEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var AuditEventFilterActionsEnum;
(function (AuditEventFilterActionsEnum) {
    AuditEventFilterActionsEnum["Allow"] = "allow";
    AuditEventFilterActionsEnum["Redact"] = "redact";
    AuditEventFilterActionsEnum["Deny"] = "deny";
})(AuditEventFilterActionsEnum || (AuditEventFilterActionsEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var AuditEventLogActionEnum;
(function (AuditEventLogActionEnum) {
    AuditEventLogActionEnum["Allow"] = "allow";
    AuditEventLogActionEnum["Redact"] = "redact";
    AuditEventLogActionEnum["Deny"] = "deny";
})(AuditEventLogActionEnum || (AuditEventLogActionEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var CreateOrUpdateRuleDtoActionEnum;
(function (CreateOrUpdateRuleDtoActionEnum) {
    CreateOrUpdateRuleDtoActionEnum["Allow"] = "allow";
    CreateOrUpdateRuleDtoActionEnum["Redact"] = "redact";
    CreateOrUpdateRuleDtoActionEnum["Deny"] = "deny";
})(CreateOrUpdateRuleDtoActionEnum || (CreateOrUpdateRuleDtoActionEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var CreateOrUpdateUserEntitySearchMethodDtoTypeEnum;
(function (CreateOrUpdateUserEntitySearchMethodDtoTypeEnum) {
    CreateOrUpdateUserEntitySearchMethodDtoTypeEnum["REGEXP"] = "REGEXP";
    CreateOrUpdateUserEntitySearchMethodDtoTypeEnum["DICTIONARY"] = "DICTIONARY";
    CreateOrUpdateUserEntitySearchMethodDtoTypeEnum["NER"] = "NER";
})(CreateOrUpdateUserEntitySearchMethodDtoTypeEnum || (CreateOrUpdateUserEntitySearchMethodDtoTypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var ExtractedEntityWithActionDtoActionEnum;
(function (ExtractedEntityWithActionDtoActionEnum) {
    ExtractedEntityWithActionDtoActionEnum["Allow"] = "allow";
    ExtractedEntityWithActionDtoActionEnum["Redact"] = "redact";
    ExtractedEntityWithActionDtoActionEnum["Deny"] = "deny";
})(ExtractedEntityWithActionDtoActionEnum || (ExtractedEntityWithActionDtoActionEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var NerMatchingResultStatusEnum;
(function (NerMatchingResultStatusEnum) {
    NerMatchingResultStatusEnum["FAILED"] = "FAILED";
    NerMatchingResultStatusEnum["MATCHED"] = "MATCHED";
    NerMatchingResultStatusEnum["UNMATCHEDENTITY"] = "UNMATCHED_ENTITY";
    NerMatchingResultStatusEnum["MISSING"] = "MISSING";
    NerMatchingResultStatusEnum["TOOWIDEBOUNDS"] = "TOO_WIDE_BOUNDS";
    NerMatchingResultStatusEnum["EXTRA"] = "EXTRA";
    NerMatchingResultStatusEnum["UNMATCHEDBOUNDS"] = "UNMATCHED_BOUNDS";
})(NerMatchingResultStatusEnum || (NerMatchingResultStatusEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var RuleDtoActionEnum;
(function (RuleDtoActionEnum) {
    RuleDtoActionEnum["Allow"] = "allow";
    RuleDtoActionEnum["Redact"] = "redact";
    RuleDtoActionEnum["Deny"] = "deny";
})(RuleDtoActionEnum || (RuleDtoActionEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var UserEntitySearchMethodDtoTypeEnum;
(function (UserEntitySearchMethodDtoTypeEnum) {
    UserEntitySearchMethodDtoTypeEnum["REGEXP"] = "REGEXP";
    UserEntitySearchMethodDtoTypeEnum["DICTIONARY"] = "DICTIONARY";
    UserEntitySearchMethodDtoTypeEnum["NER"] = "NER";
})(UserEntitySearchMethodDtoTypeEnum || (UserEntitySearchMethodDtoTypeEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var UserMaskResponseActionEnum;
(function (UserMaskResponseActionEnum) {
    UserMaskResponseActionEnum["Allow"] = "allow";
    UserMaskResponseActionEnum["Redact"] = "redact";
    UserMaskResponseActionEnum["Deny"] = "deny";
})(UserMaskResponseActionEnum || (UserMaskResponseActionEnum = {}));
/**
 * AccessTokenApi - axios parameter creator
 * @export
 */
export const AccessTokenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiKeyCreateRequest} apiKeyCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(apiKeyCreateRequest, options = {}) {
            // verify required parameter 'apiKeyCreateRequest' is not null or undefined
            if (apiKeyCreateRequest === null || apiKeyCreateRequest === undefined) {
                throw new RequiredError("apiKeyCreateRequest", "Required parameter apiKeyCreateRequest was null or undefined when calling create1.");
            }
            const localVarPath = `/api/dataguard/web/apiKeys`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof apiKeyCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(apiKeyCreateRequest !== undefined ? apiKeyCreateRequest : {})
                : apiKeyCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling delete1.");
            }
            const localVarPath = `/api/dataguard/web/apiKeys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll2(includeDeleted, options = {}) {
            const localVarPath = `/api/dataguard/web/apiKeys`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (includeDeleted !== undefined) {
                localVarQueryParameter["includeDeleted"] = includeDeleted;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id, apiKeyUpdateRequest, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling update1.");
            }
            // verify required parameter 'apiKeyUpdateRequest' is not null or undefined
            if (apiKeyUpdateRequest === null || apiKeyUpdateRequest === undefined) {
                throw new RequiredError("apiKeyUpdateRequest", "Required parameter apiKeyUpdateRequest was null or undefined when calling update1.");
            }
            const localVarPath = `/api/dataguard/web/apiKeys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof apiKeyUpdateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(apiKeyUpdateRequest !== undefined ? apiKeyUpdateRequest : {})
                : apiKeyUpdateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AccessTokenApi - functional programming interface
 * @export
 */
export const AccessTokenApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ApiKeyCreateRequest} apiKeyCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(apiKeyCreateRequest, options) {
            const localVarAxiosArgs = AccessTokenApiAxiosParamCreator(configuration).create1(apiKeyCreateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id, options) {
            const localVarAxiosArgs = AccessTokenApiAxiosParamCreator(configuration).delete1(id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll2(includeDeleted, options) {
            const localVarAxiosArgs = AccessTokenApiAxiosParamCreator(configuration).findAll2(includeDeleted, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} id
         * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id, apiKeyUpdateRequest, options) {
            const localVarAxiosArgs = AccessTokenApiAxiosParamCreator(configuration).update1(id, apiKeyUpdateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AccessTokenApi - factory interface
 * @export
 */
export const AccessTokenApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {ApiKeyCreateRequest} apiKeyCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(apiKeyCreateRequest, options) {
            return AccessTokenApiFp(configuration).create1(apiKeyCreateRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id, options) {
            return AccessTokenApiFp(configuration).delete1(id, options)(axios, basePath);
        },
        /**
         *
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll2(includeDeleted, options) {
            return AccessTokenApiFp(configuration).findAll2(includeDeleted, options)(axios, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id, apiKeyUpdateRequest, options) {
            return AccessTokenApiFp(configuration).update1(id, apiKeyUpdateRequest, options)(axios, basePath);
        },
    };
};
/**
 * AccessTokenApi - object-oriented interface
 * @export
 * @class AccessTokenApi
 * @extends {BaseAPI}
 */
export class AccessTokenApi extends BaseAPI {
    /**
     *
     * @param {ApiKeyCreateRequest} apiKeyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenApi
     */
    create1(apiKeyCreateRequest, options) {
        return AccessTokenApiFp(this.configuration).create1(apiKeyCreateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenApi
     */
    delete1(id, options) {
        return AccessTokenApiFp(this.configuration).delete1(id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {boolean} [includeDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenApi
     */
    findAll2(includeDeleted, options) {
        return AccessTokenApiFp(this.configuration).findAll2(includeDeleted, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenApi
     */
    update1(id, apiKeyUpdateRequest, options) {
        return AccessTokenApiFp(this.configuration).update1(id, apiKeyUpdateRequest, options)(this.axios, this.basePath);
    }
}
/**
 * ApiKeyApi - axios parameter creator
 * @export
 */
export const ApiKeyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiKeyCreateRequest} apiKeyCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(apiKeyCreateRequest, options = {}) {
            // verify required parameter 'apiKeyCreateRequest' is not null or undefined
            if (apiKeyCreateRequest === null || apiKeyCreateRequest === undefined) {
                throw new RequiredError("apiKeyCreateRequest", "Required parameter apiKeyCreateRequest was null or undefined when calling create1.");
            }
            const localVarPath = `/api/dataguard/web/apiKeys`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof apiKeyCreateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(apiKeyCreateRequest !== undefined ? apiKeyCreateRequest : {})
                : apiKeyCreateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling delete1.");
            }
            const localVarPath = `/api/dataguard/web/apiKeys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll2(includeDeleted, options = {}) {
            const localVarPath = `/api/dataguard/web/apiKeys`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (includeDeleted !== undefined) {
                localVarQueryParameter["includeDeleted"] = includeDeleted;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id, apiKeyUpdateRequest, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling update1.");
            }
            // verify required parameter 'apiKeyUpdateRequest' is not null or undefined
            if (apiKeyUpdateRequest === null || apiKeyUpdateRequest === undefined) {
                throw new RequiredError("apiKeyUpdateRequest", "Required parameter apiKeyUpdateRequest was null or undefined when calling update1.");
            }
            const localVarPath = `/api/dataguard/web/apiKeys/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof apiKeyUpdateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(apiKeyUpdateRequest !== undefined ? apiKeyUpdateRequest : {})
                : apiKeyUpdateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ApiKeyApi - functional programming interface
 * @export
 */
export const ApiKeyApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ApiKeyCreateRequest} apiKeyCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(apiKeyCreateRequest, options) {
            const localVarAxiosArgs = ApiKeyApiAxiosParamCreator(configuration).create1(apiKeyCreateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id, options) {
            const localVarAxiosArgs = ApiKeyApiAxiosParamCreator(configuration).delete1(id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll2(includeDeleted, options) {
            const localVarAxiosArgs = ApiKeyApiAxiosParamCreator(configuration).findAll2(includeDeleted, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} id
         * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id, apiKeyUpdateRequest, options) {
            const localVarAxiosArgs = ApiKeyApiAxiosParamCreator(configuration).update1(id, apiKeyUpdateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ApiKeyApi - factory interface
 * @export
 */
export const ApiKeyApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {ApiKeyCreateRequest} apiKeyCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(apiKeyCreateRequest, options) {
            return ApiKeyApiFp(configuration).create1(apiKeyCreateRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id, options) {
            return ApiKeyApiFp(configuration).delete1(id, options)(axios, basePath);
        },
        /**
         *
         * @param {boolean} [includeDeleted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll2(includeDeleted, options) {
            return ApiKeyApiFp(configuration).findAll2(includeDeleted, options)(axios, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id, apiKeyUpdateRequest, options) {
            return ApiKeyApiFp(configuration).update1(id, apiKeyUpdateRequest, options)(axios, basePath);
        },
    };
};
/**
 * ApiKeyApi - object-oriented interface
 * @export
 * @class ApiKeyApi
 * @extends {BaseAPI}
 */
export class ApiKeyApi extends BaseAPI {
    /**
     *
     * @param {ApiKeyCreateRequest} apiKeyCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    create1(apiKeyCreateRequest, options) {
        return ApiKeyApiFp(this.configuration).create1(apiKeyCreateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    delete1(id, options) {
        return ApiKeyApiFp(this.configuration).delete1(id, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {boolean} [includeDeleted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    findAll2(includeDeleted, options) {
        return ApiKeyApiFp(this.configuration).findAll2(includeDeleted, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {ApiKeyUpdateRequest} apiKeyUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    update1(id, apiKeyUpdateRequest, options) {
        return ApiKeyApiFp(this.configuration).update1(id, apiKeyUpdateRequest, options)(this.axios, this.basePath);
    }
}
/**
 * ApiKeyInternalApi - axios parameter creator
 * @export
 */
export const ApiKeyInternalApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Return only valid keys by apiKey from request
         * @param {ApiKeyGetValidRequest} apiKeyGetValidRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidKeys(apiKeyGetValidRequest, options = {}) {
            // verify required parameter 'apiKeyGetValidRequest' is not null or undefined
            if (apiKeyGetValidRequest === null ||
                apiKeyGetValidRequest === undefined) {
                throw new RequiredError("apiKeyGetValidRequest", "Required parameter apiKeyGetValidRequest was null or undefined when calling getValidKeys.");
            }
            const localVarPath = `/api/internal/apiKeys/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof apiKeyGetValidRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(apiKeyGetValidRequest !== undefined ? apiKeyGetValidRequest : {})
                : apiKeyGetValidRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Find api keys by search spec
         * @param {ApiKeySearchRequest} apiKeySearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(apiKeySearchRequest, options = {}) {
            // verify required parameter 'apiKeySearchRequest' is not null or undefined
            if (apiKeySearchRequest === null || apiKeySearchRequest === undefined) {
                throw new RequiredError("apiKeySearchRequest", "Required parameter apiKeySearchRequest was null or undefined when calling search.");
            }
            const localVarPath = `/api/internal/apiKeys/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof apiKeySearchRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(apiKeySearchRequest !== undefined ? apiKeySearchRequest : {})
                : apiKeySearchRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ApiKeyInternalApi - functional programming interface
 * @export
 */
export const ApiKeyInternalApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Return only valid keys by apiKey from request
         * @param {ApiKeyGetValidRequest} apiKeyGetValidRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidKeys(apiKeyGetValidRequest, options) {
            const localVarAxiosArgs = ApiKeyInternalApiAxiosParamCreator(configuration).getValidKeys(apiKeyGetValidRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Find api keys by search spec
         * @param {ApiKeySearchRequest} apiKeySearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(apiKeySearchRequest, options) {
            const localVarAxiosArgs = ApiKeyInternalApiAxiosParamCreator(configuration).search(apiKeySearchRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ApiKeyInternalApi - factory interface
 * @export
 */
export const ApiKeyInternalApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Return only valid keys by apiKey from request
         * @param {ApiKeyGetValidRequest} apiKeyGetValidRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidKeys(apiKeyGetValidRequest, options) {
            return ApiKeyInternalApiFp(configuration).getValidKeys(apiKeyGetValidRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary Find api keys by search spec
         * @param {ApiKeySearchRequest} apiKeySearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(apiKeySearchRequest, options) {
            return ApiKeyInternalApiFp(configuration).search(apiKeySearchRequest, options)(axios, basePath);
        },
    };
};
/**
 * ApiKeyInternalApi - object-oriented interface
 * @export
 * @class ApiKeyInternalApi
 * @extends {BaseAPI}
 */
export class ApiKeyInternalApi extends BaseAPI {
    /**
     *
     * @summary Return only valid keys by apiKey from request
     * @param {ApiKeyGetValidRequest} apiKeyGetValidRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyInternalApi
     */
    getValidKeys(apiKeyGetValidRequest, options) {
        return ApiKeyInternalApiFp(this.configuration).getValidKeys(apiKeyGetValidRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Find api keys by search spec
     * @param {ApiKeySearchRequest} apiKeySearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyInternalApi
     */
    search(apiKeySearchRequest, options) {
        return ApiKeyInternalApiFp(this.configuration).search(apiKeySearchRequest, options)(this.axios, this.basePath);
    }
}
/**
 * AuditApi - axios parameter creator
 * @export
 */
export const AuditApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AuditEventFilter} auditEventFilter
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(auditEventFilter, page, size, options = {}) {
            // verify required parameter 'auditEventFilter' is not null or undefined
            if (auditEventFilter === null || auditEventFilter === undefined) {
                throw new RequiredError("auditEventFilter", "Required parameter auditEventFilter was null or undefined when calling findAll.");
            }
            const localVarPath = `/api/dataguard/web/audit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof auditEventFilter !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(auditEventFilter !== undefined ? auditEventFilter : {})
                : auditEventFilter || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetails(id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError("id", "Required parameter id was null or undefined when calling getAuditDetails.");
            }
            const localVarPath = `/api/dataguard/web/audit/{id}/details`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AuditApi - functional programming interface
 * @export
 */
export const AuditApiFp = function (configuration) {
    return {
        /**
         *
         * @param {AuditEventFilter} auditEventFilter
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(auditEventFilter, page, size, options) {
            const localVarAxiosArgs = AuditApiAxiosParamCreator(configuration).findAll(auditEventFilter, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetails(id, options) {
            const localVarAxiosArgs = AuditApiAxiosParamCreator(configuration).getAuditDetails(id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AuditApi - factory interface
 * @export
 */
export const AuditApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {AuditEventFilter} auditEventFilter
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll(auditEventFilter, page, size, options) {
            return AuditApiFp(configuration).findAll(auditEventFilter, page, size, options)(axios, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetails(id, options) {
            return AuditApiFp(configuration).getAuditDetails(id, options)(axios, basePath);
        },
    };
};
/**
 * AuditApi - object-oriented interface
 * @export
 * @class AuditApi
 * @extends {BaseAPI}
 */
export class AuditApi extends BaseAPI {
    /**
     *
     * @param {AuditEventFilter} auditEventFilter
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    findAll(auditEventFilter, page, size, options) {
        return AuditApiFp(this.configuration).findAll(auditEventFilter, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    getAuditDetails(id, options) {
        return AuditApiFp(this.configuration).getAuditDetails(id, options)(this.axios, this.basePath);
    }
}
/**
 * AuditEventsApi - axios parameter creator
 * @export
 */
export const AuditEventsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} authorization
         * @param {AuditLogsRequest} auditLogsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs(authorization, auditLogsRequest, options = {}) {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError("authorization", "Required parameter authorization was null or undefined when calling getAuditLogs.");
            }
            // verify required parameter 'auditLogsRequest' is not null or undefined
            if (auditLogsRequest === null || auditLogsRequest === undefined) {
                throw new RequiredError("auditLogsRequest", "Required parameter auditLogsRequest was null or undefined when calling getAuditLogs.");
            }
            const localVarPath = `/api/dataguard/public/audit/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter["Authorization"] = String(authorization);
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof auditLogsRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(auditLogsRequest !== undefined ? auditLogsRequest : {})
                : auditLogsRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AuditEventsApi - functional programming interface
 * @export
 */
export const AuditEventsApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} authorization
         * @param {AuditLogsRequest} auditLogsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs(authorization, auditLogsRequest, options) {
            const localVarAxiosArgs = AuditEventsApiAxiosParamCreator(configuration).getAuditLogs(authorization, auditLogsRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AuditEventsApi - factory interface
 * @export
 */
export const AuditEventsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} authorization
         * @param {AuditLogsRequest} auditLogsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLogs(authorization, auditLogsRequest, options) {
            return AuditEventsApiFp(configuration).getAuditLogs(authorization, auditLogsRequest, options)(axios, basePath);
        },
    };
};
/**
 * AuditEventsApi - object-oriented interface
 * @export
 * @class AuditEventsApi
 * @extends {BaseAPI}
 */
export class AuditEventsApi extends BaseAPI {
    /**
     *
     * @param {string} authorization
     * @param {AuditLogsRequest} auditLogsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditEventsApi
     */
    getAuditLogs(authorization, auditLogsRequest, options) {
        return AuditEventsApiFp(this.configuration).getAuditLogs(authorization, auditLogsRequest, options)(this.axios, this.basePath);
    }
}
/**
 * FilterApi - axios parameter creator
 * @export
 */
export const FilterApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} filterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(filterId, options = {}) {
            // verify required parameter 'filterId' is not null or undefined
            if (filterId === null || filterId === undefined) {
                throw new RequiredError("filterId", "Required parameter filterId was null or undefined when calling _delete.");
            }
            const localVarPath = `/api/dataguard/web/filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(filterId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createOrUpdateFilterDto, options = {}) {
            // verify required parameter 'createOrUpdateFilterDto' is not null or undefined
            if (createOrUpdateFilterDto === null ||
                createOrUpdateFilterDto === undefined) {
                throw new RequiredError("createOrUpdateFilterDto", "Required parameter createOrUpdateFilterDto was null or undefined when calling create.");
            }
            const localVarPath = `/api/dataguard/web/filters`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOrUpdateFilterDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOrUpdateFilterDto !== undefined ? createOrUpdateFilterDto : {})
                : createOrUpdateFilterDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} page
         * @param {number} size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll1(page, size, options = {}) {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError("page", "Required parameter page was null or undefined when calling findAll1.");
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError("size", "Required parameter size was null or undefined when calling findAll1.");
            }
            const localVarPath = `/api/dataguard/web/filters`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} filterId
         * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(filterId, createOrUpdateFilterDto, options = {}) {
            // verify required parameter 'filterId' is not null or undefined
            if (filterId === null || filterId === undefined) {
                throw new RequiredError("filterId", "Required parameter filterId was null or undefined when calling update.");
            }
            // verify required parameter 'createOrUpdateFilterDto' is not null or undefined
            if (createOrUpdateFilterDto === null ||
                createOrUpdateFilterDto === undefined) {
                throw new RequiredError("createOrUpdateFilterDto", "Required parameter createOrUpdateFilterDto was null or undefined when calling update.");
            }
            const localVarPath = `/api/dataguard/web/filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(filterId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOrUpdateFilterDto !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOrUpdateFilterDto !== undefined ? createOrUpdateFilterDto : {})
                : createOrUpdateFilterDto || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * FilterApi - functional programming interface
 * @export
 */
export const FilterApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} filterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(filterId, options) {
            const localVarAxiosArgs = FilterApiAxiosParamCreator(configuration)._delete(filterId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createOrUpdateFilterDto, options) {
            const localVarAxiosArgs = FilterApiAxiosParamCreator(configuration).create(createOrUpdateFilterDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} page
         * @param {number} size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll1(page, size, options) {
            const localVarAxiosArgs = FilterApiAxiosParamCreator(configuration).findAll1(page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} filterId
         * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(filterId, createOrUpdateFilterDto, options) {
            const localVarAxiosArgs = FilterApiAxiosParamCreator(configuration).update(filterId, createOrUpdateFilterDto, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * FilterApi - factory interface
 * @export
 */
export const FilterApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} filterId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(filterId, options) {
            return FilterApiFp(configuration)._delete(filterId, options)(axios, basePath);
        },
        /**
         *
         * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createOrUpdateFilterDto, options) {
            return FilterApiFp(configuration).create(createOrUpdateFilterDto, options)(axios, basePath);
        },
        /**
         *
         * @param {number} page
         * @param {number} size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll1(page, size, options) {
            return FilterApiFp(configuration).findAll1(page, size, options)(axios, basePath);
        },
        /**
         *
         * @param {number} filterId
         * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(filterId, createOrUpdateFilterDto, options) {
            return FilterApiFp(configuration).update(filterId, createOrUpdateFilterDto, options)(axios, basePath);
        },
    };
};
/**
 * FilterApi - object-oriented interface
 * @export
 * @class FilterApi
 * @extends {BaseAPI}
 */
export class FilterApi extends BaseAPI {
    /**
     *
     * @param {number} filterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    _delete(filterId, options) {
        return FilterApiFp(this.configuration)._delete(filterId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    create(createOrUpdateFilterDto, options) {
        return FilterApiFp(this.configuration).create(createOrUpdateFilterDto, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} page
     * @param {number} size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    findAll1(page, size, options) {
        return FilterApiFp(this.configuration).findAll1(page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} filterId
     * @param {CreateOrUpdateFilterDto} createOrUpdateFilterDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    update(filterId, createOrUpdateFilterDto, options) {
        return FilterApiFp(this.configuration).update(filterId, createOrUpdateFilterDto, options)(this.axios, this.basePath);
    }
}
/**
 * MaskingApi - axios parameter creator
 * @export
 */
export const MaskingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Extract named entities from text using user rules.
         * @param {ExtractNamedEntitiesRequest} extractNamedEntitiesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractEntities(extractNamedEntitiesRequest, options = {}) {
            // verify required parameter 'extractNamedEntitiesRequest' is not null or undefined
            if (extractNamedEntitiesRequest === null ||
                extractNamedEntitiesRequest === undefined) {
                throw new RequiredError("extractNamedEntitiesRequest", "Required parameter extractNamedEntitiesRequest was null or undefined when calling extractEntities.");
            }
            const localVarPath = `/api/dataguard/masking/extract-entities`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof extractNamedEntitiesRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(extractNamedEntitiesRequest !== undefined
                    ? extractNamedEntitiesRequest
                    : {})
                : extractNamedEntitiesRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MaskingApi - functional programming interface
 * @export
 */
export const MaskingApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Extract named entities from text using user rules.
         * @param {ExtractNamedEntitiesRequest} extractNamedEntitiesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractEntities(extractNamedEntitiesRequest, options) {
            const localVarAxiosArgs = MaskingApiAxiosParamCreator(configuration).extractEntities(extractNamedEntitiesRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * MaskingApi - factory interface
 * @export
 */
export const MaskingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Extract named entities from text using user rules.
         * @param {ExtractNamedEntitiesRequest} extractNamedEntitiesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractEntities(extractNamedEntitiesRequest, options) {
            return MaskingApiFp(configuration).extractEntities(extractNamedEntitiesRequest, options)(axios, basePath);
        },
    };
};
/**
 * MaskingApi - object-oriented interface
 * @export
 * @class MaskingApi
 * @extends {BaseAPI}
 */
export class MaskingApi extends BaseAPI {
    /**
     *
     * @summary Extract named entities from text using user rules.
     * @param {ExtractNamedEntitiesRequest} extractNamedEntitiesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaskingApi
     */
    extractEntities(extractNamedEntitiesRequest, options) {
        return MaskingApiFp(this.configuration).extractEntities(extractNamedEntitiesRequest, options)(this.axios, this.basePath);
    }
}
/**
 * NamedEntityApi - axios parameter creator
 * @export
 */
export const NamedEntityApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options = {}) {
            // verify required parameter 'createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod' is not null or undefined
            if (createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod ===
                null ||
                createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod ===
                    undefined) {
                throw new RequiredError("createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod", "Required parameter createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod was null or undefined when calling createUserEntitySearchMethod.");
            }
            const localVarPath = `/api/dataguard/web/named-entities/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod !==
                "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod !==
                    undefined
                    ? createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
                    : {})
                : createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod ||
                    "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} searchMethodId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEntitySearchMethod(searchMethodId, options = {}) {
            // verify required parameter 'searchMethodId' is not null or undefined
            if (searchMethodId === null || searchMethodId === undefined) {
                throw new RequiredError("searchMethodId", "Required parameter searchMethodId was null or undefined when calling deleteUserEntitySearchMethod.");
            }
            const localVarPath = `/api/dataguard/web/named-entities/user/{searchMethodId}`.replace(`{${"searchMethodId"}}`, encodeURIComponent(String(searchMethodId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEntities(options = {}) {
            const localVarPath = `/api/dataguard/web/named-entities`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserEntities(options = {}) {
            const localVarPath = `/api/dataguard/web/named-entities/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options = {}) {
            // verify required parameter 'createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod' is not null or undefined
            if (createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod ===
                null ||
                createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod ===
                    undefined) {
                throw new RequiredError("createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod", "Required parameter createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod was null or undefined when calling updateUserEntitySearchMethod.");
            }
            const localVarPath = `/api/dataguard/web/named-entities/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod !==
                "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod !==
                    undefined
                    ? createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
                    : {})
                : createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod ||
                    "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * NamedEntityApi - functional programming interface
 * @export
 */
export const NamedEntityApiFp = function (configuration) {
    return {
        /**
         *
         * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options) {
            const localVarAxiosArgs = NamedEntityApiAxiosParamCreator(configuration).createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} searchMethodId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEntitySearchMethod(searchMethodId, options) {
            const localVarAxiosArgs = NamedEntityApiAxiosParamCreator(configuration).deleteUserEntitySearchMethod(searchMethodId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEntities(options) {
            const localVarAxiosArgs = NamedEntityApiAxiosParamCreator(configuration).findAllEntities(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserEntities(options) {
            const localVarAxiosArgs = NamedEntityApiAxiosParamCreator(configuration).findAllUserEntities(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options) {
            const localVarAxiosArgs = NamedEntityApiAxiosParamCreator(configuration).updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * NamedEntityApi - factory interface
 * @export
 */
export const NamedEntityApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options) {
            return NamedEntityApiFp(configuration).createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options)(axios, basePath);
        },
        /**
         *
         * @param {number} searchMethodId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserEntitySearchMethod(searchMethodId, options) {
            return NamedEntityApiFp(configuration).deleteUserEntitySearchMethod(searchMethodId, options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllEntities(options) {
            return NamedEntityApiFp(configuration).findAllEntities(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserEntities(options) {
            return NamedEntityApiFp(configuration).findAllUserEntities(options)(axios, basePath);
        },
        /**
         *
         * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options) {
            return NamedEntityApiFp(configuration).updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options)(axios, basePath);
        },
    };
};
/**
 * NamedEntityApi - object-oriented interface
 * @export
 * @class NamedEntityApi
 * @extends {BaseAPI}
 */
export class NamedEntityApi extends BaseAPI {
    /**
     *
     * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntityApi
     */
    createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options) {
        return NamedEntityApiFp(this.configuration).createUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} searchMethodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntityApi
     */
    deleteUserEntitySearchMethod(searchMethodId, options) {
        return NamedEntityApiFp(this.configuration).deleteUserEntitySearchMethod(searchMethodId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntityApi
     */
    findAllEntities(options) {
        return NamedEntityApiFp(this.configuration).findAllEntities(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntityApi
     */
    findAllUserEntities(options) {
        return NamedEntityApiFp(this.configuration).findAllUserEntities(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {CreateOrUpdateDictionaryUserEntitySearchMethod | CreateOrUpdateRegexpUserEntitySearchMethod} createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntityApi
     */
    updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options) {
        return NamedEntityApiFp(this.configuration).updateUserEntitySearchMethod(createOrUpdateDictionaryUserEntitySearchMethodCreateOrUpdateRegexpUserEntitySearchMethod, options)(this.axios, this.basePath);
    }
}
/**
 * PlaygroundApi - axios parameter creator
 * @export
 */
export const PlaygroundApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {PlaygroundRequest} playgroundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEntities(playgroundRequest, options = {}) {
            // verify required parameter 'playgroundRequest' is not null or undefined
            if (playgroundRequest === null || playgroundRequest === undefined) {
                throw new RequiredError("playgroundRequest", "Required parameter playgroundRequest was null or undefined when calling findEntities.");
            }
            const localVarPath = `/api/dataguard/web/playground`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof playgroundRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(playgroundRequest !== undefined ? playgroundRequest : {})
                : playgroundRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PlaygroundApi - functional programming interface
 * @export
 */
export const PlaygroundApiFp = function (configuration) {
    return {
        /**
         *
         * @param {PlaygroundRequest} playgroundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEntities(playgroundRequest, options) {
            const localVarAxiosArgs = PlaygroundApiAxiosParamCreator(configuration).findEntities(playgroundRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * PlaygroundApi - factory interface
 * @export
 */
export const PlaygroundApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {PlaygroundRequest} playgroundRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEntities(playgroundRequest, options) {
            return PlaygroundApiFp(configuration).findEntities(playgroundRequest, options)(axios, basePath);
        },
    };
};
/**
 * PlaygroundApi - object-oriented interface
 * @export
 * @class PlaygroundApi
 * @extends {BaseAPI}
 */
export class PlaygroundApi extends BaseAPI {
    /**
     *
     * @param {PlaygroundRequest} playgroundRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaygroundApi
     */
    findEntities(playgroundRequest, options) {
        return PlaygroundApiFp(this.configuration).findEntities(playgroundRequest, options)(this.axios, this.basePath);
    }
}
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/healthCheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options = {}) {
            const localVarPath = `/api/dataguard/web`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version1(options = {}) {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version1(options) {
            const localVarAxiosArgs = SystemApiAxiosParamCreator(configuration).version1(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return SystemApiFp(configuration).healthCheck(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(options) {
            return SystemApiFp(configuration).version(options)(axios, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version1(options) {
            return SystemApiFp(configuration).version1(options)(axios, basePath);
        },
    };
};
/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    healthCheck(options) {
        return SystemApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version(options) {
        return SystemApiFp(this.configuration).version(options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    version1(options) {
        return SystemApiFp(this.configuration).version1(options)(this.axios, this.basePath);
    }
}
/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {RunTestsRequest} runTestsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runBatch(runTestsRequest, options = {}) {
            // verify required parameter 'runTestsRequest' is not null or undefined
            if (runTestsRequest === null || runTestsRequest === undefined) {
                throw new RequiredError("runTestsRequest", "Required parameter runTestsRequest was null or undefined when calling runBatch.");
            }
            const localVarPath = `/api/dataguard/tests/run-batch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof runTestsRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(runTestsRequest !== undefined ? runTestsRequest : {})
                : runTestsRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function (configuration) {
    return {
        /**
         *
         * @param {RunTestsRequest} runTestsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runBatch(runTestsRequest, options) {
            const localVarAxiosArgs = TestApiAxiosParamCreator(configuration).runBatch(runTestsRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {RunTestsRequest} runTestsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runBatch(runTestsRequest, options) {
            return TestApiFp(configuration).runBatch(runTestsRequest, options)(axios, basePath);
        },
    };
};
/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     *
     * @param {RunTestsRequest} runTestsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    runBatch(runTestsRequest, options) {
        return TestApiFp(this.configuration).runBatch(runTestsRequest, options)(this.axios, this.basePath);
    }
}
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options = {}) {
            const localVarPath = `/api/dataguard/web/users/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options) {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).currentUser(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        currentUser(options) {
            return UserApiFp(configuration).currentUser(options)(axios, basePath);
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    currentUser(options) {
        return UserApiFp(this.configuration).currentUser(options)(this.axios, this.basePath);
    }
}
/**
 * UserMaskingApi - axios parameter creator
 * @export
 */
export const UserMaskingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Replace all named-entity occurrences in text with placeholders
         * @param {UserMaskRequest} userMaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mask(userMaskRequest, options = {}) {
            // verify required parameter 'userMaskRequest' is not null or undefined
            if (userMaskRequest === null || userMaskRequest === undefined) {
                throw new RequiredError("userMaskRequest", "Required parameter userMaskRequest was null or undefined when calling mask.");
            }
            const localVarPath = `/api/dataguard/user-masking/mask`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof userMaskRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(userMaskRequest !== undefined ? userMaskRequest : {})
                : userMaskRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the text with original unmasked values by masking identifier
         * @param {UserUnmaskRequest} userUnmaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmask(userUnmaskRequest, options = {}) {
            // verify required parameter 'userUnmaskRequest' is not null or undefined
            if (userUnmaskRequest === null || userUnmaskRequest === undefined) {
                throw new RequiredError("userUnmaskRequest", "Required parameter userUnmaskRequest was null or undefined when calling unmask.");
            }
            const localVarPath = `/api/dataguard/user-masking/unmask`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof userUnmaskRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(userUnmaskRequest !== undefined ? userUnmaskRequest : {})
                : userUnmaskRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * UserMaskingApi - functional programming interface
 * @export
 */
export const UserMaskingApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Replace all named-entity occurrences in text with placeholders
         * @param {UserMaskRequest} userMaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mask(userMaskRequest, options) {
            const localVarAxiosArgs = UserMaskingApiAxiosParamCreator(configuration).mask(userMaskRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get the text with original unmasked values by masking identifier
         * @param {UserUnmaskRequest} userUnmaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmask(userUnmaskRequest, options) {
            const localVarAxiosArgs = UserMaskingApiAxiosParamCreator(configuration).unmask(userUnmaskRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * UserMaskingApi - factory interface
 * @export
 */
export const UserMaskingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Replace all named-entity occurrences in text with placeholders
         * @param {UserMaskRequest} userMaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mask(userMaskRequest, options) {
            return UserMaskingApiFp(configuration).mask(userMaskRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary Get the text with original unmasked values by masking identifier
         * @param {UserUnmaskRequest} userUnmaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmask(userUnmaskRequest, options) {
            return UserMaskingApiFp(configuration).unmask(userUnmaskRequest, options)(axios, basePath);
        },
    };
};
/**
 * UserMaskingApi - object-oriented interface
 * @export
 * @class UserMaskingApi
 * @extends {BaseAPI}
 */
export class UserMaskingApi extends BaseAPI {
    /**
     *
     * @summary Replace all named-entity occurrences in text with placeholders
     * @param {UserMaskRequest} userMaskRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMaskingApi
     */
    mask(userMaskRequest, options) {
        return UserMaskingApiFp(this.configuration).mask(userMaskRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get the text with original unmasked values by masking identifier
     * @param {UserUnmaskRequest} userUnmaskRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserMaskingApi
     */
    unmask(userUnmaskRequest, options) {
        return UserMaskingApiFp(this.configuration).unmask(userUnmaskRequest, options)(this.axios, this.basePath);
    }
}
