import React from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { appHasSystemFeature } from '../../../api/cc.api';
import { isDemoMode } from '../../../api/client';
import { isAlpha } from '../../../isAlpha';
import { isTovie } from '../../../isTovie';
import { isX5 } from '../../../isX5';
import { currentUser } from '../../../models/currentUser';
import { goToAccount, goToMain, goToCorporate } from '../../../routes';
import { SidebarButton } from '../../Sidebar/SidebarButton';
import { tourRestart$ } from '../../Tour/tourRestart';
import CommunityDropdown from './CommunityDropdown';
import HelpDropdown from './HelpDropdown';
import styles from './style.module.scss';

type Props = {
  toggleSidebar: () => void;
};

export const ChatbarSettings = ({ toggleSidebar }: Props) => {
  const { t } = useTranslation();

  const corporatePageShown =
    !currentUser.value?.organization && !isX5 && !isTovie && appHasSystemFeature('corporate_page');

  return (
    <div className={cn('flex flex-col text-sm border-top-gray-200 bg-gray-200', styles.chatbarSettings__wrapper)}>
      {isAlpha ? (
        <SidebarButton
          text={t('restartOnboarding')}
          iconLeft='fadGraduationCap'
          onClick={() => {
            tourRestart$.next(true);
            goToMain();
          }}
        />
      ) : (
        <>
          {corporatePageShown && (
            <SidebarButton
              text={t('corporateBtn')}
              dataTestId='Sidebar:CorpPageBtn'
              iconLeft='faStar'
              disabled={isDemoMode.value}
              onClick={() => {
                toggleSidebar();
                goToCorporate();
              }}
              className={styles.chatbarSettings__btnFancy}
            />
          )}
          <SidebarButton
            text={t('account')}
            dataTestId='Sidebar.AccountPageBtn'
            iconLeft='farUserCircle'
            disabled={isDemoMode.value}
            onClick={() => {
              toggleSidebar();
              goToAccount();
            }}
          />
          {/* {!isTovie && (
            <a href={t('docsUrl')} target='_blank' rel='noopener noreferrer' className='display-contents'>
              <SidebarButton dataTestId='Sidebar:DocsBtn' text={t('docs')} iconLeft='farInfoCircle' />
            </a>
          )} */}

          <HelpDropdown />
          {!isTovie && appHasSystemFeature('community_links') && <CommunityDropdown />}
        </>
      )}
    </div>
  );
};
