import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';

import { ExpensesHistoryResponse, ReportUser } from '@just-ai/api/dist/generated/CopilotGateway/api';
import { Tabs, useTranslation, usePromiseProcessing, Button, Spinner } from '@just-ai/just-ui';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import { DateTime } from 'luxon';
import { currentUser, hasPermission } from 'models/currentUser';
import useApiService from 'services/useApiService';

import { useAppContext } from '../../../contexts/appContext';
import { AnalyticsDatePicker } from '../components/AnalyticsDatePicker';
import { BarChart } from '../components/BarChart';
import { DonutChart } from '../components/DonutChart';
import { AnalyticsTable } from '../components/Tables';
import { ReplenishTable } from '../components/Tables/ReplenishTable';
import { UserExpenses } from '../components/UserExpenses';
import { downloadReportAsFile, getExpenseRequestParams } from '../components/utils';
import styles from './styles.module.scss';

export const TabsTypes = {
  EXPENSES: 'expenses',
  EXPENSES_DAILY: 'expensesDaily',
  REPLENISH: 'replenish',
};

const defaultDate = {
  startDate: DateTime.now().startOf('day').minus({ week: 1 }).toJSDate(),
  endDate: DateTime.now().endOf('day').toJSDate(),
};

export const AnalyticsTab = memo(() => {
  const { t } = useTranslation();

  const { state: appState } = useAppContext();

  const [activeTab, setTab] = useState(TabsTypes.EXPENSES);
  const [expensesData, setExpensesData] = useState<ExpensesHistoryResponse>();

  const [drawerData, setDrawerData] = useState<ReportUser | null>(null);

  const { paginationInfo, changePage } = usePagination({
    pageNum: expensesData?.page.number ?? 0,
    pageSize: expensesData?.page.size ?? 10,
    totalCount: expensesData?.page.totalElements ?? 0,
    totalPages: expensesData?.page.totalPages ?? 0,
  });

  const { getExpensesHistory, getExpensesReportUrl } = useApiService();
  const [dates, setDates] = useState(defaultDate);
  const [search, setSearch] = useState('');

  const setSearchHandler = useCallback((value: string) => {
    setSearch(value.trimStart());
  }, []);

  const isAdmin = hasPermission('COPILOT_VIEW_ACCOUNT_ANALYTICS');
  const hasInvitedUsers = Boolean(
    currentUser.value?.numberOfUsersInAccount && currentUser.value?.numberOfUsersInAccount > 1
  );

  const [{ loading }, getDefaultExpensesData] = usePromiseProcessing(
    async () => {
      const { groupBy, sort } = getExpenseRequestParams(hasInvitedUsers, isAdmin);
      const { data } = await getExpensesHistory({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        groupBy,
        page: paginationInfo.pageNum,
        size: paginationInfo.pageSize,
        locale: appState.locale,
        sort,
        search,
      });
      setExpensesData(data);
    },
    {
      deps: [dates, getExpensesHistory, paginationInfo.pageNum, paginationInfo.pageSize, search],
    }
  );

  useEffect(() => {
    getDefaultExpensesData();
  }, [getDefaultExpensesData]);

  const tabs = useMemo(() => {
    if (!isAdmin)
      return [
        {
          value: TabsTypes.EXPENSES,
          name: t(Boolean(currentUser.value?.organization) ? 'Analytics:Tab:UserExpenses' : 'Analytics:Tab:Expenses'),
        },
      ];
    return [
      {
        value: TabsTypes.EXPENSES,
        name: t(Boolean(currentUser.value?.organization) ? 'Analytics:Tab:UserExpenses' : 'Analytics:Tab:Expenses'),
      },
      {
        value: TabsTypes.EXPENSES_DAILY,
        name: t('Analytics:Tab:ExpensesByDay'),
      },
      {
        value: TabsTypes.REPLENISH,
        name: t('Analytics:Tab:Replenishment'),
      },
    ];
  }, [isAdmin, t]);

  const handleTabChange = useCallback(
    (value: string) => {
      setTab(value);
      changePage(0);
    },
    [changePage]
  );

  const toggleDrawer = useCallback((value?: ReportUser) => {
    setDrawerData(value ? value : null);
  }, []);

  const [{ loading: reportLoading }, getExpensesReportLocal] = usePromiseProcessing(
    async () => {
      const url = await getExpensesReportUrl({
        periodFrom: dates.startDate,
        periodTo: dates.endDate,
        locale: appState.locale,
      });
      downloadReportAsFile(
        url,
        `Report: ${dates.startDate.toLocaleDateString(appState.locale)} - ${dates.endDate.toLocaleDateString(
          appState.locale
        )}.xlsx`
      );
    },
    {
      deps: [dates, getExpensesReportUrl],
    }
  );

  if (loading || reportLoading) {
    return (
      <div className='h-full flex'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='flex w-full flex-column'>
      <div className='flex w-full justify-between items-center'>
        <AnalyticsDatePicker dates={dates} setDates={setDates} />
        {/*
        TODO return after fixes from backend
        <Button
          color='secondary'
          outline
          size='sm'
          className='d-none d-sm-flex'
          iconRight='farArrowToBottom'
          onClick={getExpensesReportLocal}
        >
          {t('Analytics:Button:Download')}
        </Button>
         */}
      </div>
      {hasPermission('COPILOT_VIEW_ACCOUNT_ANALYTICS') ? (
        <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} className={styles.tabs} />
      ) : (
        <hr />
      )}
      {activeTab === TabsTypes.EXPENSES && (
        <>
          {expensesData && expensesData.page.totalElements > 0 && <DonutChart dates={dates} />}
          <AnalyticsTable
            data={expensesData}
            tab={activeTab}
            openDrawer={toggleDrawer}
            hasPermission={isAdmin && hasInvitedUsers}
            searchHandler={setSearchHandler}
            searchVal={search}
          />
          {paginationInfo.totalPages > 1 && (
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              changePage={changePage}
              totalCount={paginationInfo.totalCount}
            />
          )}
        </>
      )}
      {activeTab === TabsTypes.EXPENSES_DAILY && <BarChart dates={dates} />}
      {activeTab === TabsTypes.REPLENISH && <ReplenishTable dates={dates} hasPermission={isAdmin} />}

      {drawerData && <UserExpenses drawerData={drawerData} close={toggleDrawer} initialDates={dates} />}
    </div>
  );
});

AnalyticsTab.displayName = 'AnalyticsTab';
