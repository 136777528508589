import React, { useState } from 'react';

import {
  DropdownButton,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  useToggle,
  useTranslation,
  Icon,
} from '@just-ai/just-ui';
import cn from 'classnames';

import { appHasSystemFeature } from '../../../api/cc.api';
import useApiService from '../../../services/useApiService';
import { isMobile } from '../../../utils/app/common';
import { HelpDialog } from '../../Settings/HelpDialog';
import { SidebarButton } from '../../Sidebar/SidebarButton';
import styles from './style.module.scss';

export default function HelpDropdown() {
  const [helpModal, showHelpModal, hideHelpModal] = useToggle(false);

  const [isSupportDialogOpen, setIsSupportDialog] = useState<boolean>(false);
  const { sendUserActionToAnalytics } = useApiService();

  const handleSupportClick = () => {
    sendUserActionToAnalytics({ eventName: 'PressSupport' });
    setIsSupportDialog(true);
  };

  const { t } = useTranslation();

  if (isMobile())
    return (
      <>
        <SidebarButton
          text={t('help')}
          dataTestId='Sidebar:HelpBtn'
          iconLeft='farQuestionCircle'
          iconRight='farChevronRight'
          onClick={showHelpModal}
        />
        <Modal
          isOpen={helpModal}
          className={cn('mobileBottomModal', styles.chatbarSettings__modal)}
          onClickCloseIcon={hideHelpModal}
          hideFooter
        >
          {appHasSystemFeature('support') && (
            <p className={cn('d-flex mt-5', styles.chatbarSettings__modalLink)} onClick={handleSupportClick}>
              <Icon name='farInfoCircle' color='secondary' />
              {t('support')}
            </p>
          )}
          <a
            href={t('docsUrl')}
            rel='noopener noreferrer'
            target='_blank'
            className={cn('d-flex mb-5', styles.chatbarSettings__modalLink)}
          >
            <Icon name='farBook' color='secondary' />
            <p className='text-default mr-auto'>{t('docsAndLearning')}</p>
            <Icon name='farExternalLinkSquare' color='secondary' />
          </a>
        </Modal>
        <HelpDialog
          open={isSupportDialogOpen}
          onClose={() => {
            setIsSupportDialog(false);
          }}
        />
      </>
    );

  return (
    <>
      <DropdownButton data-test-id='dropdown-wrapper' direction='right' className='w-full'>
        <DropdownToggle color='none' className='w-full p-0' data-test-id='dropdown-toggle' size='default'>
          <SidebarButton
            text={t('help')}
            dataTestId='Sidebar:HelpBtn'
            iconLeft='farQuestionCircle'
            iconRight='farChevronRight'
          />
        </DropdownToggle>
        <DropdownMenu data-test-id='dropdown-menu' className={styles.chatbarSettings__dropdown}>
          {appHasSystemFeature('support') && (
            <DropdownItem
              className='flex items-center'
              onClick={handleSupportClick}
              iconLeft='farInfoCircle'
              data-test-id='Sidebar:SupportPageBtn'
              iconSize='md'
              iconColor='secondary'
            >
              <p className='text-default'>{t('support')}</p>
            </DropdownItem>
          )}
          <DropdownItem
            className='flex items-center'
            iconLeft='farBook'
            iconRight='farExternalLinkSquare'
            data-test-id='Sidebar:DocsBtn'
            iconSize='md'
            iconColor='secondary'
          >
            <a href={t('docsUrl')} target='_blank' rel='noopener noreferrer' className='d-contents'>
              <p className='text-default'>{t('docsAndLearning')}</p>
            </a>
          </DropdownItem>
        </DropdownMenu>
      </DropdownButton>
      <HelpDialog
        open={isSupportDialogOpen}
        onClose={() => {
          setIsSupportDialog(false);
        }}
      />
    </>
  );
}
