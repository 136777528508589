import React, { KeyboardEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Icon, IconButton, InputText, Modal, Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { useLocation } from 'react-router';

import { isDemoMode } from '../../api/client';
import { useAppContext, useSidebarActions } from '../../contexts/appContext';
import { showSignupModal } from '../../hooks/showSignup';
import { isTovie } from '../../isTovie';
import { saveNewTitle } from '../../models/conversations';
import { templates } from '../../models/templates';
import { goToMain } from '../../routes';
import useApiService from '../../services/useApiService';
import { Conversation } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import { processConversationContext, processHistory } from '../../utils/app/conversation';
import { handleAgentSettingsMobileToggle, isOpenAgentSettings } from '../Settings/AgentSettingsSidebar';
import { CloseSidebarButton } from '../Sidebar/components/OpenCloseButton';
import ChatContextValueMobile from './ChatContextValueMobile';
import { resetContextSilent } from './signals/ChatUpdateSignal';
import styles from './style.module.scss';

type Props = {
  selectedConversation: Conversation;
  fullWidth: boolean;
};

export default function Header({ selectedConversation, fullWidth }: Props) {
  const {
    state: { lightMode },
    handleUpdateConversation,
    handleDeleteConversation,
  } = useAppContext();
  const { pathname } = useLocation();
  const { clearChatHistory } = useApiService();

  const { templatesMap } = templates.value;

  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState('');

  const [confirmClearModal, setConfirmClearModal] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  useEffect(() => {
    setIsRenaming(false);
    setIsDeleting(false);
  }, [selectedConversation.id]);

  const handleEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      selectedConversation && handleRename(selectedConversation);
    }
  };

  const handleRename = (conversation: Conversation) => {
    if (renameValue.trim().length > 0) {
      handleUpdateConversation(conversation, [
        {
          key: 'name',
          value: renameValue,
        },
        {
          key: 'config',
          value: { ...conversation.config, name: renameValue },
        },
      ]);
      saveNewTitle({ id: selectedConversation.id, title: renameValue });
      setRenameValue('');
      setIsRenaming(false);
    }
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    if (!selectedConversation) return;
    if (isDeleting) {
      if (pathname.includes(selectedConversation.id)) goToMain();
      handleDeleteConversation(selectedConversation.id);
    } else if (isRenaming) {
      handleRename(selectedConversation);
    }
    setIsDeleting(false);
    setIsRenaming(false);
  };

  const handleCancel: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setIsDeleting(false);
    setIsRenaming(false);
  };

  const handleHistoryReset = async () => {
    setIsClearing(true);
    const { data: respo } = await clearChatHistory(selectedConversation.id);
    const processedHistory = processHistory(respo.history);
    handleUpdateConversation(selectedConversation, [
      {
        key: 'history',
        value: processedHistory,
      },
      { key: 'contextValue', value: processConversationContext(processedHistory) },
    ]);
    setIsClearing(false);
    setConfirmClearModal(false);
  };

  const handleAgentSettingsReset = useCallback(() => {
    resetContextSilent.value = true;
  }, []);

  const [toggleSidebar] = useSidebarActions();

  const isMain = selectedConversation.app.template === 'main';

  const handleClearHistoryClick = () =>
    isDemoMode.value ? showSignupModal(true) : isMain ? setConfirmClearModal(true) : handleAgentSettingsReset();

  const hideContextBlock = useMemo(
    () =>
      (!isMain && !templatesMap[selectedConversation.app.template]?.clientFeatures?.contextManagementEnabled) ||
      !Boolean(selectedConversation.contextValue),
    [isMain, selectedConversation.app.template, selectedConversation.contextValue, templatesMap]
  );

  if (!selectedConversation) return null;

  if (isTovie && isMain) return null;

  return (
    <div
      className={cn(
        `flex align-items-center border-bottom-gray-200 w-100 ${lightMode} overflow-hidden`,
        styles.chat__header,
        {
          'overflow-hidden': !isMobile(),
        }
      )}
      style={fullWidth ? { gridColumn: '1 / -1' } : undefined}
    >
      <div className='flex d-sm-none'>
        <CloseSidebarButton onClick={toggleSidebar} side='left' />
      </div>
      {isRenaming ? (
        <InputText
          className={cn('flex-1', styles.chat__editInput)}
          data-test-id='Chat.name_input'
          value={renameValue}
          onChange={value => setRenameValue(value)}
          onKeyDown={handleEnterDown}
          autoFocus
          maxLength={45}
        />
      ) : (
        <div className='flex flex-1 align-items-center gap-4 overflow-hidden'>
          <div
            data-test-id='Chat.name_static'
            className={cn('text-ellipsis color-gray-950', styles.chat__header__name)}
          >
            <b className='text-truncate'>{isMain ? t('assistantJay') : selectedConversation.name}</b>
          </div>
          <IconButton
            className={cn(styles.chat__header__icon, 'd-none d-md-flex')}
            data-test-id='Chat.name_btn_edit'
            outline
            name='farPen'
            onClick={() => {
              setIsRenaming(true);
              setRenameValue(selectedConversation?.name || selectedConversation?.config.info.title);
            }}
          />
        </div>
      )}

      {isDeleting || isRenaming ? (
        <>
          <IconButton
            data-test-id='Chat.name_btn_accept'
            className={cn(styles.chat__header__icon)}
            name='farCheck'
            onClick={handleConfirm}
          />
          <IconButton
            data-test-id='Chat.name_btn_cancel'
            className={styles.chat__header__icon}
            name='farTimes'
            onClick={handleCancel}
          />
        </>
      ) : (
        <div className={cn('flex ', { 'gap-8': !isMobile(), 'gap-16': isMobile() })}>
          {!isMobile() && !hideContextBlock && (
            <div className={cn('btn-group')}>
              <div className={cn(styles.chat__header__contextBlock)}>
                {t('ChatHeader:context')}:{' '}
                <b className={cn({ 'text-danger': selectedConversation.contextValue.messagesTruncationMode })}>
                  {selectedConversation.contextValue.fullness}%
                </b>
                <div className={styles.chat__header__qIcon} id='chatHeaderContextIcon'>
                  <Icon className='flex' name='faQuestion' size='xs' />
                </div>
                <Tooltip target='chatHeaderContextIcon'>{t('ChatHeader:contextTooltip')}</Tooltip>
              </div>
              <Button
                size='sm'
                outline
                color='secondary'
                className={styles.chat__header__reset}
                wrapperIconClassName={styles.chat__header__icon}
                disabled={selectedConversation.messageIsStreaming}
                onClick={handleClearHistoryClick}
              >
                {t('ChatHeader:clearHistory')}
              </Button>
            </div>
          )}
          <Modal
            isOpen={confirmClearModal}
            inProgress={isClearing}
            className='mobileBottomModal'
            title={t('ClearHistoryModal:Title')}
            buttonSubmitText={t('clear')}
            onCancelClick={() => setConfirmClearModal(false)}
            onActionClick={handleHistoryReset}
            buttonCancelColor='secondary'
          >
            <p className='mb-0'>{t('ClearHistoryModal:Text')}</p>
          </Modal>

          {isMobile() && isOpenAgentSettings.value ? (
            <IconButton
              data-test-id='Chat.agent_settings_btn'
              className={cn(styles.chat__header__icon, 'd-sm-none')}
              name='farTimes'
              onClick={handleAgentSettingsMobileToggle}
            />
          ) : !isMain ? (
            <div className={cn('flex', { 'flex-row-reverse': isMobile(), 'gap-16': isMobile() })}>
              {isMobile() && !hideContextBlock ? (
                <ChatContextValueMobile
                  clearHistory={handleClearHistoryClick}
                  openSettings={handleAgentSettingsMobileToggle}
                  deleteChat={() => setIsDeleting(true)}
                  contextValue={selectedConversation.contextValue}
                />
              ) : (
                <IconButton
                  outline
                  color='secondary'
                  className={cn(styles.chat__header__icon, {
                    'cursor-not-allowed': selectedConversation.messageIsStreaming,
                  })}
                  data-test-id='Chat.btn_delete'
                  name='farTrashAlt'
                  disabled={selectedConversation.messageIsStreaming}
                  onClick={() => setIsDeleting(true)}
                />
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}
